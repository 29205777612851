import { Injectable } from '@angular/core';
import { SocketService } from 'src/app/core/backend-adapter/socket.service';
import { NotifyService } from 'src/app/core/layouts/notifications/notify/notify.service';
import { DirectoryWrapperComponent } from '../../directory-wrapper/directory-wrapper.component';
import { DirectoryFlatListingsRow, DirectoryListingsRow } from '../directory-listings.interfaces';
import {
  SqlListingsQueryObj,
  SqlListingsRawData,
  SqlListingsRow,
  SqlListingsRowDictType,
  SQL_LISTINGS_RAW_ROW_KEY,
} from './sql-listings.interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { LISTINGS_SQL } from './sql-listings.sql';
import { SessionService } from 'src/app/core/backend-adapter/session.service';

// tslint:disable-next-line: member-ordering
const LISTINGS_IDS = {
  all: 'all_publishers_url',
  pandio: 'pandio_urls',
  appleMaps: 'applemaps_urls',
  yelp: 'yelp_managers_detail',
  // gbp: 'gmb_urls',
};

@Injectable()
export class SqlListingsAdapter {
  private allListings$$ = new BehaviorSubject<DirectoryListingsRow[]>(null);
  private ident: string;
  private sqlListingsData: SqlListingsRawData;

  constructor(
    private sessionService: SessionService,
    private notifyService: NotifyService,
    private socketService: SocketService
  ) {}

  public getAllListings$(): Observable<DirectoryListingsRow[]> {
    return this.allListings$$.asObservable();
  }

  public retrieveAllSqlListings(): Promise<any> {
    this.ident = LISTINGS_IDS.all;

    const queryArgs: SqlListingsQueryObj = { queryIdent: this.ident };
    if (this.sessionService.hasPerm('report.sql-adhoc', 'r')) {
      queryArgs.querySql = LISTINGS_SQL.all;
    }

    return this.socketService
      .sendRequest('run-sql-query', queryArgs)
      .then((res: SqlListingsRawData) => {
        this.sqlListingsData = res;
        this.allListings$$.next(this.mapSqlListings(res.rows));
        return true;
      })
      .catch((err) => {
        if (err.category == 'DB') {
          this.notifyService.error('Loading listings error: ', err.message);
        } else {
          this.notifyService.error('Could not run report');
          console.warn('Could not run report: ' + err.message);
        }
        this.sqlListingsData = { rows: [] } as any;
        this.allListings$$.next([]);
        return false;
      });
  }

  mapAllListingsTableData(): DirectoryFlatListingsRow[] {
    return this.sqlListingsData.rows.map((dict: SqlListingsRowDictType<SQL_LISTINGS_RAW_ROW_KEY>) => {
      const row = {
        locationId: dict[SQL_LISTINGS_RAW_ROW_KEY.DBID],
        location: dict[SQL_LISTINGS_RAW_ROW_KEY.BusinessName],
        storeCode: dict[SQL_LISTINGS_RAW_ROW_KEY.StoreCode],
        yelp: dict[SQL_LISTINGS_RAW_ROW_KEY.YELPURL],
        mapquest: dict[SQL_LISTINGS_RAW_ROW_KEY.MAPQUESTURL],
        pandio: JSON.parse(dict[SQL_LISTINGS_RAW_ROW_KEY.YPDEXSPURLs]),
        appleMaps: dict[SQL_LISTINGS_RAW_ROW_KEY.APPLEMAPSURL],
        bing: dict[SQL_LISTINGS_RAW_ROW_KEY.BingURL],
        gmb: dict[SQL_LISTINGS_RAW_ROW_KEY.GMBURL],
        nextdoor: dict[SQL_LISTINGS_RAW_ROW_KEY.NEXTDOORURL],
        factual: dict[SQL_LISTINGS_RAW_ROW_KEY.FACTUALURL],
        tomtom: dict[SQL_LISTINGS_RAW_ROW_KEY.TOMTOMURL],
        here: dict[SQL_LISTINGS_RAW_ROW_KEY.HEREURL],
        amazonalexa: dict[SQL_LISTINGS_RAW_ROW_KEY.AMAZONALEXAURL],
        facebook: dict[SQL_LISTINGS_RAW_ROW_KEY.FACEBOOKURL],
        infogroup: dict[SQL_LISTINGS_RAW_ROW_KEY.INFOGROUPURL],
        localeze: dict[SQL_LISTINGS_RAW_ROW_KEY.LOCALEZEURL],
        uber: dict[SQL_LISTINGS_RAW_ROW_KEY.UBERURL],
        ypca: dict[SQL_LISTINGS_RAW_ROW_KEY.YPCAURL],
        faFoursquare: dict[SQL_LISTINGS_RAW_ROW_KEY.FOURSQUARE],
      };
      return row;
    });
  }
  // SocketService.sendRequest('get-publisher-urls', { where: `storeCode = '101'`})
  private mapSqlListings(dataRows: Array<SqlListingsRowDictType<SQL_LISTINGS_RAW_ROW_KEY>>): DirectoryListingsRow[] {
    const rows = dataRows.map((dict: SqlListingsRowDictType<SQL_LISTINGS_RAW_ROW_KEY>) => {
      const row: SqlListingsRow = {
        locationId: dict[SQL_LISTINGS_RAW_ROW_KEY.DBID],
        location: dict[SQL_LISTINGS_RAW_ROW_KEY.BusinessName],
        storeCode: dict[SQL_LISTINGS_RAW_ROW_KEY.StoreCode],
        vendor: {
          yelp: dict[SQL_LISTINGS_RAW_ROW_KEY.YELPURL],
          mapquest: dict[SQL_LISTINGS_RAW_ROW_KEY.MAPQUESTURL],
          pandio: JSON.parse(dict[SQL_LISTINGS_RAW_ROW_KEY.YPDEXSPURLs]),
          appleMaps: dict[SQL_LISTINGS_RAW_ROW_KEY.APPLEMAPSURL],
          bing: dict[SQL_LISTINGS_RAW_ROW_KEY.BingURL],
          gmb: dict[SQL_LISTINGS_RAW_ROW_KEY.GMBURL],
          nextdoor: dict[SQL_LISTINGS_RAW_ROW_KEY.NEXTDOORURL],
          factual: dict[SQL_LISTINGS_RAW_ROW_KEY.FACTUALURL],
          tomtom: dict[SQL_LISTINGS_RAW_ROW_KEY.TOMTOMURL],
          here: dict[SQL_LISTINGS_RAW_ROW_KEY.HEREURL],
          amazonalexa: dict[SQL_LISTINGS_RAW_ROW_KEY.AMAZONALEXAURL],
          facebook: dict[SQL_LISTINGS_RAW_ROW_KEY.FACEBOOKURL],
          infogroup: dict[SQL_LISTINGS_RAW_ROW_KEY.INFOGROUPURL],
          localeze: dict[SQL_LISTINGS_RAW_ROW_KEY.LOCALEZEURL],
          uber: dict[SQL_LISTINGS_RAW_ROW_KEY.UBERURL],
          ypca: dict[SQL_LISTINGS_RAW_ROW_KEY.YPCAURL],
          foursquare: dict[SQL_LISTINGS_RAW_ROW_KEY.FOURSQUARE],
        },
      };
      return row;
    });

    return rows.reduce((acc: DirectoryListingsRow[], row: SqlListingsRow) => {
      Object.entries(row.vendor).forEach(([vendor, link]) => {
        acc.push({
          locationId: row.locationId,
          location: row.location,
          storeCode: row.storeCode,
          vendor,
          link,
        } as DirectoryListingsRow);
      });

      return acc;
    }, []);
  }
}
