import { Component, OnDestroy, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { combineLatest, concat, forkJoin, merge, Observable, Subject, zip } from 'rxjs';
import { map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { DBService } from 'src/app/core/backend-adapter/db.service';
import { IFilterData, FILTER_TYPES } from 'src/app/core/feature-modules/table-filter/table-filter.interfaces';
import { TableFilterService } from 'src/app/core/feature-modules/table-filter/table-filter.service';
import { SYSTEM_COLORS } from 'src/app/core/feature-modules/whitelabel/style-changer/styles/colors/system-colors.constants';
import { NotifyService } from 'src/app/core/layouts/notifications/notify/notify.service';
import { GBPService } from 'src/app/gbp-management/gbp.service';
import { ICols, IFitersObject } from 'src/app/review-management/review-management.interfaces';
import { GbpQaCustomerAnswersComponent } from '../gbp-qa-customer-answers/gbp-qa-customer-answers.component';
import { GbpQaReplyComponent } from '../gbp-qa-reply/gbp-qa-reply.component';
import {BroadcastService} from '@app/core/backend-adapter/broadcast.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {API_URL} from '../../../../../config';
import {CookieService} from '@app/core/backend-adapter/cookie.service';
import { saveAs } from 'file-saver';
import { GBPFiltersDateService } from 'src/app/gbp-management/services/gbp-filters-date-service/gbp-filters-date-service';
import { SessionService } from 'src/app/core/backend-adapter/session.service';

@Component({
  selector: 'app-gbp-qa-customer-view',
  templateUrl: './gbp-qa-customer-view.component.html',
  styleUrls: ['./gbp-qa-customer-view.component.scss'],
})
export class GbpQaCustomerViewComponent implements OnInit, OnDestroy {
  SYSTEM_COLORS = SYSTEM_COLORS;
  private unsubscribe$ = new Subject<void>();

  allQuestions;
  initialalQuestions;
  loading = true;
  keyword = '';
  offset = 0;
  pageSize = 10;
  sortOrder = '';
  cols: ICols[] = [];
  totalQuestionsCount = 0;
  qaData = [];
  filterData: IFilterData[];
  filters: IFitersObject;
  chipsArray: string[] = [];
  tooltipText = 'Coming soon. Please reach out to support if you need to edit a question.';
  allAnsObservables$: Observable<any>[];
  allAnswersList = [];

  constructor(
    public dialogService: DialogService,
    private tableFilterService: TableFilterService,
    private gbpService: GBPService,
    private notifyService: NotifyService,
    private dbService: DBService,
    private broadcastService: BroadcastService,
    private cookieService: CookieService,
    private http: HttpClient,
    private gbpFiltersDateService: GBPFiltersDateService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.filterData = [
      {
        type: FILTER_TYPES.DATE,
        title: 'DATE',
        options: ['This month', 'Last month'],
      },
      {
        type: FILTER_TYPES.MULTI,
        title: 'ANSWERED BY',
        options: ['Owner', 'Customers'],
      },
    ];

    this.cols = [
      { field: 'questionText', header: 'QUESTION' },
      { field: 'businessName', header: 'LOCATION' },
      { field: 'storeCode', header: 'STORE CODE' },
      { field: 'createTime', header: 'DATE POSTED' },
      { field: 'ans_owner', header: 'OWNER ANSWER' },
      { field: 'ans_count_cust', header: 'CUSTOMERS ANSWERS' },
    ];

    this.setSubscriptions();
  }

  setSubscriptions() {
    this.broadcastService.subscribe('answerDetailGMBUpdated', (repl) => {
      this.loadQuestions();
    });
  }

  openReply(rowData) {
    if(!this.checkEditPerms) return;
    this.dialogService.open(GbpQaReplyComponent, {
      data: { ...rowData },
      width: '50%',
    });
  }

  openAnswers(rowData, type: string) {
    this.dialogService.open(GbpQaCustomerAnswersComponent, {
      data: {
        question: rowData,
        type,
      },
      width: '50%',
    });
  }

  openExport() {
    // open popup
  }

  private loadQuestions() {
    this.loading = true;
    this.dbService.QuestionGMB.loadObjects({
      offset: this.offset,
      limit: this.pageSize,
      order: this.sortOrder,
      where: this.buildWhereClause(),
    })
      .then(
        (repl) => {
          if (repl.collection?.length == 0) {
            this.notifyService.error('There are no questions available');
            this.allQuestions = [];
            this.totalQuestionsCount = 0;
          } else {
            this.allQuestions = repl.collection;
            this.totalQuestionsCount = repl.totalEntries;
            this.gbpService.account_id = repl.collection[0]?.account_id;

            // this.getAnswers();
          }
        },
        (err: Error) => {
          this.allQuestions = [];
          console.warn('Error loading Questions: %j', err);
        }
      )
      .finally(() => {
        this.loading = false;
      });
  }

  private buildWhereClause(): string {
    const filterClauses: string[] = [];
    const tempFilterArr: string[] = [];
    const isFiltersApplied = this.chipsArray.length > 0;
    filterClauses.push(`authorType != 'MERCHANT'${isFiltersApplied || this.keyword ? ' AND ' : ''}`);
    const pushQueriesToMainArray = () => {
      filterClauses.push(`(${tempFilterArr.join(' OR ')})`);
      tempFilterArr.length = 0;
    };

    // build query if filtering is used
    if (isFiltersApplied) {
      for (const [key, value] of Object.entries(this.filters)) {
        if (value.length > 0 && value[0] != undefined) {
          switch (key) {
            case 'ANSWERED BY':
              value.forEach((element: string) => {
                switch (element) {
                  case 'Owner':
                    tempFilterArr.push(`EXISTS (SELECT true FROM answergmb a WHERE a.question_id = questiongmb._id AND authorType = 'MERCHANT') `);
                    break;
                  case 'Customers':
                    tempFilterArr.push(`EXISTS (SELECT true FROM answergmb a WHERE a.question_id = questiongmb._id AND authorType != 'MERCHANT')`);
                    break;
                }
              });
              pushQueriesToMainArray();
              break;
            case 'DATE':
              value.forEach((element: string) => {
                switch (element) {
                  case 'This month':
                    tempFilterArr.push(this.gbpFiltersDateService.inThisMonth('updateTime'));
                    break;
                  case 'Last month':
                    tempFilterArr.push(this.gbpFiltersDateService.inLastMonth('updateTime'));
                    break;
                  default:
                    const dateArray: string[] = element.split(' - ');
                    tempFilterArr.push(
                      `updateTime >= TO_DATE('${dateArray[0]}', 'YYYY-MM-DD') AND updateTime <= TO_DATE('${dateArray[1]}', 'YYYY-MM-DD')`
                    );
                    break;
                }
              });
              pushQueriesToMainArray();
              break;
          }
        }
      }
    }

    // build query if searching is used
    if (this.keyword) {
      // tempFilterArr.push(`storeCode::text ILIKE '${this.keyword}%'`);
      tempFilterArr.push(`businessName::text ILIKE '%${this.keyword}%'`);
      tempFilterArr.push(`storeCode::text ILIKE '%${this.keyword}%'`);
      tempFilterArr.push(`questionText::text ILIKE '%${this.keyword}%'`);
      pushQueriesToMainArray();
    }

    // join all queries
    let whereClauseString = filterClauses[0] + filterClauses.slice(1).join(' AND ');
    whereClauseString += isFiltersApplied || this.keyword ? '' : '';
    return whereClauseString;
  }

  lazyLoadQuestions(event: LazyLoadEvent) {
    this.offset = event.first;
    this.pageSize = event.rows;
    if (event.sortField) {
      this.sortOrder = `${event.sortField}${event.sortOrder < 0 ? ' DESC' : ''}`;
    }
    return this.loadQuestions();
  }

  onChipsRefresh(filters: IFitersObject) {
    this.chipsArray = [].concat.apply([], Object.values(filters)); // merge all checkbox values into one array
    this.chipsArray = this.chipsArray.filter((e) => e); // remove all possible undefined

    this.filters = filters;
    this.loadQuestions();
  }

  ngOnDestroy(): void {
    this.tableFilterService.clearFilters(); // clear table filters
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  exportData() {
    const AUTH_ID = this.cookieService.get(this.cookieService.COOKIE_ID_CONSTANT);
    const options = {
      headers: new HttpHeaders({
        'content-type': 'application/json',
        Authorization: `Bearer ${AUTH_ID}`,
      }),
      responseType: 'text' as any,
    };
    const url = `${API_URL}/download/sql-report/gmb_customer_qanda_all`;
    const query = `?format=csv`;

    this.http.get(`${url}${query}`, options).subscribe(
      (result) => {
        const blob = new Blob([result], {
          type: 'text/plain;charset=utf-8',
        });

        saveAs(blob, `gmb-customer-qanda-all.csv`);
        // this.notify.success('Downloaded successfully!');
      },
      (error) => {
        this.notifyService.error('Error while exporting');
      }
    );
  }

  get checkEditPerms(): boolean {
    return this.sessionService.hasPerm('qanda', 'e');
  }

}
