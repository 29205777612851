<ng-container *ngIf="isStub">
  <ng-template *ngTemplateOutlet="directoryStub"></ng-template>
</ng-container>

<div *ngIf="!isStub" class="directory-container">
  <div class="directory-status" [ngClass]="d.publish ? 'green' : 'gray'">
    {{ d.publish ? 'Publishing' : 'Not Publishing' }}
  </div>
  <ng-template *ngTemplateOutlet="viewDirectoryUser"></ng-template>
  <ng-template *ngTemplateOutlet="viewDirectoryAdmin"></ng-template>

  <app-directory-card-edit
    *ngIf="!disableEdit && mode === 'edit' && user.role === 'admin'"
    [d]="d"
    (saveEvent)="saveDirectory($event)"
    (cancelEdit)="mode = 'view'"
  >
  </app-directory-card-edit>
</div>

<ng-template #viewDirectoryUser>
  <div class="directory-view" *ngIf="user.role === 'user' && mode === 'view'">
    <section class="dir-info">
      <div class="dir-title ttc">{{ d._vendorName }}</div>
      <button *ngIf="shouldDisplayViewListings" class="listings" (click)="handleExternalLinkOpening(d)">
        view listings&nbsp;<i class="pi pi-external-link"></i>
      </button>
    </section>

    <ng-template *ngTemplateOutlet="dirLogo"></ng-template>
  </div>
</ng-template>

<ng-template #viewDirectoryAdmin>
  <div class="directory-view" *ngIf="user.role === 'admin' && mode === 'view'">
    <section class="dir-info">
      <div class="dir-title ttc">{{ d._vendorName }}</div>
      <button *ngIf="!disableEdit" class="dir-edit" (click)="handleEdit()"><i class="pi pi-pencil"></i></button>
    </section>

    <hr class="full-width"/>
    <ng-template *ngTemplateOutlet="dirLogo"></ng-template>

    <hr/>

    <section class="dir-categories">
      <h5>Categories</h5>
      <div class="categories">
        <ng-container *ngFor="let c of d.cats">
          <div *ngIf="c !== null" [pTooltip]="c" [tooltipPosition]="'top'" class="category-badge ws-nw">{{ c }}</div>
        </ng-container>
        <div class="no-categories-text" *ngIf="!d.cats.length && !d.isLoading">Categories not set</div>
        <div *ngIf="d.isLoading" class="no-categories-text display_loading">Loading Categories...<p-progressSpinner *ngIf="d.isLoading"
            styleClass="w-2rem h-2rem" strokeWidth="2" fill="var(--surface-ground)"
            animationDuration="2s">
          </p-progressSpinner></div>
      </div>
    </section>
  </div>
</ng-template>

<ng-template #dirLogo>
  <section class="dir-logo">
    <div class="dir-vendor">
      <img class="vendor-image" [src]="VENDOR_IMAGES[d.vendor]" appImageOnError/>
      <!-- INFO: postponed due backend data emptyness -->
      <!-- <img *ngIf="d.publish" class="status-icon" pTooltip="Verified" src="/assets/directories/statuses/verified.png" /> -->
      <!-- <img
        *ngIf="!d.publish"
        class="status-icon"
        pTooltip="Not verified"
        src="/assets/directories/statuses/has-errors.png"
      /> -->
    </div>
    <div>
      <button *ngIf="(user.role === 'admin') && shouldDisplayViewListings" class="listings" (click)="handleExternalLinkOpening(d)">
        view listings&nbsp;<i class="pi pi-external-link"></i>
      </button>
      <ng-template *ngTemplateOutlet="dirAccuracy"></ng-template>
    </div>

  </section>
</ng-template>

<ng-template #dirAccuracy>
  <section *ngIf="sessionService.hasPerm('report.accuracy', 'r') && d.accuracy && d['showAccuracyReport']" class="dir-accuracy">
    <ul>
      <li>Business Name <i [pTooltip]="accuracyNameTip()" [class]="accuracyNameIcon()"></i></li>
      <li>Address <i [pTooltip]="accuracyAddressTip()" [class]="accuracyAddressIcon()"></i></li>
      <li>Phone <i [pTooltip]="accuracyPhoneTip()" [class]="accuracyPhoneIcon()"></i></li>
      <li>Website <i [pTooltip]="accuracyWebsiteTip()" [class]="accuracyWebsiteIcon()"></i></li>
      <li>Hours <i [pTooltip]="accuracyHoursTip()" [class]="accuracyHoursIcon()"></i></li>
    </ul>

  </section>
</ng-template>

<ng-template #directoryStub>
  <div class="directory-stub-container">
    <div class="directory-status">
      <span> + </span>

    </div>
    <div class="directory-view">
      <section class="dir-stub-text">
        <br/>
        <div>Looking to add other directories?</div>
        <h3 class="dir-title ttc m-t-0">Get in touch with your CSM!</h3>
        <div>They'd be happy to help.</div>
        <svg
          style="margin: 5px"
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.604 16.041a2.188 2.188 0 1 0 0-4.375 2.188 2.188 0 0 0 0 4.375zM12.396 16.041a2.188 2.188 0 1 0 0-4.375 2.188 2.188 0 0 0 0 4.375z"
            fill="#414042"
          />
          <path
            d="M17.5 23.333c-2.159 0-4.01-1.181-5.031-2.916h-2.436a8.014 8.014 0 0 0 14.934 0H22.53c-1.006 1.735-2.873 2.916-5.031 2.916zm-.015-20.416C9.435 2.916 2.916 9.45 2.916 17.5s6.52 14.583 14.57 14.583c8.064 0 14.597-6.533 14.597-14.583S25.55 2.916 17.485 2.916zm.015 26.25c-6.446 0-11.667-5.221-11.667-11.667S11.054 5.833 17.5 5.833 29.166 11.054 29.166 17.5 23.947 29.166 17.5 29.166z"
            fill="#414042"
          />
        </svg>
      </section>
    </div>
  </div>
</ng-template>

<p-dialog
  class="modal-listings"
  modal="true"
  closable="true"
  closeOnEscape="true"
  dismissableMask="true"
  [(visible)]="isOpenModal"
  appendTo="body"
>
  <app-modal-directory-listings
    *ngIf="isOpenModal"
    [account]="account"
    [location]="location"
    [directory]="d"
  ></app-modal-directory-listings>
</p-dialog>
