import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { from, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AccountsService } from 'src/app/acct-comps/accounts.service';
import { SessionService } from 'src/app/core/backend-adapter/session.service';
import { SocketService } from 'src/app/core/backend-adapter/socket.service';
import { ReviewResponseTemplateService } from '../../services/review-response-template.service';
import { NotifyService } from 'src/app/core/layouts/notifications/notify/notify.service';
import { IAccount } from 'src/app/acct-comps/accounts.interfaces';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  templateUrl: './add-template-response.component.html',
  styleUrls: ['./add-template-response.component.scss'],
  selector: 'app-add-template-response',
})
export class AddTemplateResponseComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$: Subject<any> = new Subject();
  private currentResponseBlockIndex: number = 0;
  private userLoginId: number;
  private allAccountIds: number[] = [];
  private currentAccountId: number;
  private editSelectedTemplateObject: any = null;
  private addNewResponseFlag: string = null;

  public templateForm = this.fb.group({
    star_rating: this.fb.group({
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
    }),
    thumb_rating: [''],
    review_text_presence: [''],
    accounts: [],
    tags: [],
    responses: this.fb.array([this.fb.group({})]), // Edit template property
    response_texts: this.fb.array([this.fb.control([])]), // Add new template property.
  });
  public accounts: any[] = [];
  public tags: any[] = [];
  public variablesList: any[] = [
    { name: 'Business name', value: '{businessname}' },
    { name: 'city', value: '{city}' },
    { name: 'state', value: '{state}' },
    { name: 'Phone number', value: '{phonenumber}' },
    { name: 'Reviewer name', value: '{reviewername}' },
    { name: 'Star rating', value: '{starrating}' },
  ];
  public selectedVariable: any;
  public starRatingsData: any[] = [];
  public thumbRatingsData: any[] = [];
  public spinnerAnimationDuration: string = '0.8s';
  public isLoading: boolean = false;
  public templateId: string;

  constructor(
    private fb: FormBuilder,
    private accountsService: AccountsService,
    public socketService: SocketService,
    private sessionService: SessionService,
    private reviewResponseTemplateService: ReviewResponseTemplateService,
    private notifyService: NotifyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getRouteParams();
    this.getCurrentAccountDetails();

    // If account Id is missing display a notification to ask user's to select an account inorder to create a template else get the accounts and tags.
    if (!this.currentAccountId) {
      this.notifyService.info('Please select an account to create Review templates.', 5000);
    } else {
      this.getAccounts();
      this.getTags();
      this.getCurrentUserDetails();
    }

    this.starRatingsData = this.reviewResponseTemplateService.starRatingsData;
    this.thumbRatingsData = this.reviewResponseTemplateService.thumbRatingsData;
  }

  /**
   * @description: Get the activated route params.
   */
  private getRouteParams(): void {
    this.activatedRoute.paramMap
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        switchMap((params: ParamMap) => {
          this.templateId = params.get('id');
          if (this.templateId) {
            return this.reviewResponseTemplateService.getSelectedTemplate$;
          } else {
            return of(null);
          }
        })
      )
      .subscribe((res: any) => {
        this.editSelectedTemplateObject = res;

        /* This scenario is for when the user directly hits the URL with the template ID or refreshes the page and the behaviourSubject looses
         selected template data, then we redirect user back to templates list page to select the template again. */
        if (this.templateId && !this.editSelectedTemplateObject) {
          // this.notifyService.info('Template not found. Please go back and select the template again.'); // Not working.
          this.router.navigate(['/response-templates']);
        }
        /* This is when we have the template ID and the behaviourSubject has the selected template data, then we set the form data.*/
        if (this.templateId && this.editSelectedTemplateObject) {
          this.setEditTemplateFormData();
        }
      });
  }

  private setEditTemplateFormData(): void {
    // Note the accounts control will be set once we have the list of all accounts.
    if (this.editSelectedTemplateObject) {
      const { filter, tags, responses } = this.editSelectedTemplateObject;
      const { star_rating = [], thumb_rating, review_text_presence } = filter;

      this.templateForm.patchValue({
        star_rating: {
          1: star_rating.includes(1) && 1,
          2: star_rating.includes(2) && 2,
          3: star_rating.includes(3) && 3,
          4: star_rating.includes(4) && 4,
          5: star_rating.includes(5) && 5,
        },
        thumb_rating: thumb_rating,
        review_text_presence:
          typeof review_text_presence === 'boolean' ? JSON.stringify(review_text_presence) : review_text_presence,
        tags: tags,
      });

      // Adding this control to display/add/edit/delete responses of an already existing template.
      if (responses.length) {
        this.templateForm.setControl('responses', this.fb.array(responses.map((r) => this.fb.group({ ...r }))));
      }
      // Adding this control if user wants ro add a new response to an existing template.
      // this.templateForm.setControl('response_texts', this.fb.array([this.fb.control['']]));
    }
  }

  /**
   * @description: Get the current account details.
   */
  private getCurrentAccountDetails(): void {
    this.sessionService
      .getSelectedAccount$()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((account: IAccount) => {
        this.allAccountIds = !account?.isLeaf ? [account?._id, ...this.getSubAccounts(account)] : [account?._id];
        this.currentAccountId = account?._id;
      });
  }

  private getSubAccounts(account: IAccount): number[] {
    const subAccounts: number[] = [];
    account?._subAccounts?.forEach((subAccount) => {
      subAccounts.push(subAccount?._id);
    });
    return subAccounts;
  }

  /**
   * @description: Get the current user details.
   * @returns: void
   * @arguments: void
   */
  private getCurrentUserDetails(): void {
    this.sessionService
      .getCurrentUser$()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((user) => {
        this.userLoginId = user?.login?._id;
      });
  }

  /**
   * @description: Get all accounts from the API.
   * @returns: void
   */
  private getAccounts(): void {
    this.accountsService
      .getAccounts()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((accounts) => {
        const mappedAccounts = this.accountsService.mapAccountsWithSubaccounts(accounts['collection']);
        this.allAccountIds.forEach((acc) => {
          mappedAccounts.forEach((account) => {
            if (account._id === acc) {
              this.accounts.push({
                ident: account['ident'],
                id: account['_id'],
              });
            }
          });
        });

        /* This is when we have the template ID and the behaviourSubject has the selected template data, then we set the accounts control in the form data.
        * Reason to add this code here, is since we get the list of all accounts from backend.
        */
        if (this.templateId) {
          const { accounts } = this.editSelectedTemplateObject;
          const selectedAccounts = [];
          accounts.forEach((a) => {
            this.accounts.forEach((acc) => {
              if (a === acc['id']) {
                selectedAccounts.push(acc);
              }
            });
          });
          this.templateForm.patchValue({
            accounts: [...selectedAccounts],
          });
        }

        // Commenting customColDef variables for now.
        // mappedAccounts.forEach((acc) => {
        //   acc['properties'] &&
        //     acc['properties']?.customColumnDefs?.length &&
        //     acc['properties'].customColumnDefs.forEach((c) => {
        //       this.variablesList.push({
        //         name: c.label,
        //         value: `{${c.ident}}`,
        //       });
        //     });
        // });
      });
  }

  /**
   * @description: Get tags from the API.
   * @returns: void
   */
  private getTags(): void {
    const tags$ = from(
      this.socketService.sendRequest('get-location-tags-and-counts', {
        where: ['-and'],
      })
    );
    tags$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((tags) => {
      this.tags = tags['collection'];
    });
  }

  /**
   * @description: Getter for the responses form array.
   */
  get responseTexts(): FormArray {
    return this.templateForm.get('response_texts') as FormArray;
  }

  /**
   * @description To create a new response template.
   * @augments void
   * @returns void
   */
  public addResponse(): void {
    this.responseTexts.push(this.fb.control(''));
  }

  /**
   * @description Removes a response from the template.
   * @param i The index of the response to delete.
   * @returns void
   */
  public deleteResponse(i: number): void {
    this.responseTexts.removeAt(i);
  }

  /**
   * @description Getter for list of responses from an existing templates.
   */
  get prevResponses(): FormArray {
    return this.templateForm.get('responses') as FormArray;
  }

  /**
   * @description Removes a response from an existing template.
   * @param i The index of the response to delete.
   * @returns void
   */
  public deleteExistingResponse(i: number): void {
    return this.prevResponses.removeAt(i);
  }

  /**
   * @description Adds a variable to the selected response block.
   * @param variable selected variable to add to the response block.
   */
  public addVariable(variable: any): void {
    if (this.addNewResponseFlag === 'add') {
      const currentResponses = this.responseTexts.value as string[];
      currentResponses[this.currentResponseBlockIndex] = `${currentResponses[this.currentResponseBlockIndex]} ${
        variable.value
      }`;
      this.responseTexts.patchValue(currentResponses);
    }

    if (this.addNewResponseFlag === 'update') {
      const currentResponses = this.prevResponses.value as string[];
      currentResponses[this.currentResponseBlockIndex]['response_text'] = `${
        currentResponses[this.currentResponseBlockIndex]['response_text']
      } ${variable.value}`;
      this.prevResponses.patchValue(currentResponses);
    }
  }

  /**
   * @description Sets focus on the selected response block.
   * @param i takes the index of the current response block.
   * @param val if the selected textarea is part of edit template or new template.
   * @returns void
   */
  public selectedResponseBlock(i: number, val: string): void {
    this.addNewResponseFlag = val;
    this.currentResponseBlockIndex = i;
  }

  /**
   * @description To submit the form.
   * @returns void
   */
  public saveTemplate(): void {
    if (this.templateId) {
      this.updateTemptate();
    }
    if (!this.templateId) {
      this.addTemplate();
    }
  }

  /**
   * @description: To update a template.
   */
  private updateTemptate(): void {
    this.isLoading = true;
    const updatedPayload = this.mapTemplateObjectData();
    if (!updatedPayload['filter']?.hasOwnProperty('star_rating')) {
      updatedPayload['filter']['star_rating'] = null;
    }

    this.reviewResponseTemplateService
      .updateTemplate(updatedPayload, this.currentAccountId, this.userLoginId)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (response) => {
          this.isLoading = false;
          this.router.navigate(['/response-templates']);
          this.notifyService.success('Template updated successfully.');
        },
        (error) => {
          this.isLoading = false;
          this.notifyService.error('Error updating template.');
        }
      );
  }

  /**
   * @description: To add a new template.
   */
  private addTemplate(): void {
    this.isLoading = true;
    const payload = this.mapTemplateObjectData();
    if (!payload?.hasOwnProperty('star_rating')) {
      payload['star_rating'] = null;
    }
    this.reviewResponseTemplateService
      .addTemplate(payload, this.currentAccountId, this.userLoginId)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (response) => {
          this.isLoading = false;
          this.router.navigate(['/response-templates']);
          this.notifyService.success('Template created successfully.');
        },
        (error) => {
          this.isLoading = false;
          this.notifyService.error('Error creating template.');
        }
      );
  }

  /**
   * @description Method return a payload object to either create/update a template.
   */
  private mapTemplateObjectData(): {} {
    let payload = {
      accounts: this.templateForm.value.accounts.map(el => el.id),
      tags: !this.templateForm.value.tags?.length ? null : this.templateForm.value.tags,
    };

    let reviewTextPresence: boolean | string;
    if (
      typeof this.templateForm.value.review_text_presence === 'string' &&
      this.templateForm.value.review_text_presence
    ) {
      switch (this.templateForm.value.review_text_presence) {
        case 'true':
          reviewTextPresence = true;
          break;
        case 'false':
          reviewTextPresence = false;
          break;
        default:
          reviewTextPresence = 'both';
      }
    } else {
      reviewTextPresence = null;
    }

    if (this.templateId) {
      payload['status'] = this.editSelectedTemplateObject['status'];
      payload['id'] = this.templateId;
      payload['responses'] = this.templateForm.value.responses?.length ? this.templateForm.value.responses : null;
      payload['response_texts'] = this.templateForm.value.response_texts[0]?.length
        ? this.templateForm.value.response_texts
        : null;
      payload['filter'] = {
        thumb_rating: this.templateForm.value.thumb_rating,
        review_text_presence: reviewTextPresence,
      };
      Object.keys(this.templateForm.controls['star_rating'].value).forEach((key) => {
        if (this.templateForm.controls['star_rating'].value[key]) {
          payload = {
            ...payload,
            ...{
              filter: {
                ...payload['filter'],
                star_rating: [1, 2, 3, 4, 5].filter((star) => this.templateForm.controls['star_rating'].value[star]),
              },
            },
          };
        }
      });
    } else {
      payload['response_texts'] = this.templateForm.value.response_texts[0].length
        ? this.templateForm.value.response_texts
        : null;
      payload['thumb_rating'] = this.templateForm.value.thumb_rating || null;
      payload['review_text_presence'] = reviewTextPresence;
      Object.keys(this.templateForm.controls['star_rating'].value).forEach((key) => {
        if (this.templateForm.controls['star_rating'].value[key]) {
          payload = {
            ...payload,
            ...{
              star_rating: [1, 2, 3, 4, 5].filter((star) => this.templateForm.controls['star_rating'].value[star]),
            },
          };
        }
      });
    }
    return payload;
  }

  /*
   * @description: Check if user has selected at least 1 account to validate the form.
   * @returns: boolean
   */
  get isFormValid(): boolean {
    return this.templateForm.controls['accounts'].value?.length;
  }

  /**
   * @description: Unsubscribe from the observables.
   * @returns: void
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
