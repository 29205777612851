<!-- <button pButton type="button" [label]="userLetter" class="p-button-rounded user-menu"></button> -->

<div class="notification" (click)="toggleDiv()">
  <i style="font-size: 1.7rem; color: #a5a1a1; size: large" class="pi pi-bell"  [pTooltip]="numberUnread ? 'Unread notifications' : null">
    <span *ngIf="numberUnread" pBadge [value]="numberUnread"></span
  ></i>
</div>
<div *ngIf="divVisible" class="notification-tray">
  <div class="notification-tray-header">
    <h1>Notifications</h1>
    <p-tabMenu [model]="tabItems" [activeItem]="activeItem" [ngStyle]="{ width: '200px' }"></p-tabMenu>
  </div>
  <div class="notification-tray-notifications" (scroll)="onScroll($event)">
    <div class="div-button-mark-as-read">
      <button class="mark-as-read" [disabled]="!hasUnreadNotifications()" (click)="markAllAsRead(locCounts)">
        Mark all as read
      </button>
    </div>
    <div *ngIf="!locCounts?.length" class="no-notifications">
      <div class="no-notifications-text">No review notifications</div>
    </div>
    <div class="eachNotification" *ngFor="let notification of locCounts">
      <div class="flex-block-1">
        <div class="flex-block-2">
          <div class="dataFirstLine">
            A new {{ notification.reviewPublisher }} Review has been added to location {{ notification.storeCode }} ({{
              notification.city
            }}, {{ notification.state }})
          </div>
          <div class="data">
            <div class="data-1">Review date:</div>
            <div>{{ notification.reviewDate }}.</div>
          </div>
          <div class="data">
            <div class="data-2">Reviewer name:</div>
            <div>{{ notification.reviewerName }}.</div>
          </div>
          <div class="data">
            <div class="data-1">Rating:</div>
            <div>{{ notification.starRating }} stars.</div>
          </div>
          <div class="data">
            <div class="data-3">Review text:</div>
            <div class="content">
              <div *ngIf="notification?.reviewComment?.length < 50 && !notification.showFullText">
                {{ notification.reviewComment | slice : 0 : 50 }}
              </div>
              <div *ngIf="notification?.reviewComment?.length > 50 && !notification.showFullText">
                {{ notification.reviewComment | slice : 0 : 50 }}
              </div>
              <a
                *ngIf="notification?.reviewComment?.length > 50 && !notification.showFullText"
                (click)="notification.showFullText = true; $event.stopPropagation()"
                class="less-more-style"
                >More...</a
              >
              <div *ngIf="notification.showFullText">
                {{ notification.reviewComment }}
                <a class="less-more-style" (click)="notification.showFullText = false; $event.stopPropagation()"
                  >Less...</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="custom-checkbox">
          <p-checkbox
            binary="true"
            name="group"
            [(ngModel)]="notification.isRead"
            [inputId]="notification.notificationId"
            [pTooltip]="notification.isRead ? 'Mark as unread' : 'Mark as read'"
            (onChange)="updateMarkAsRead(notification)"
          ></p-checkbox>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="user-avatar user-menu">
  <img src="/assets/static/default-user.png" />
</div>

<button
  pButton
  class="p-button-text user-menu"
  type="button"
  [label]="menuExpanded ? '&#x25B2;' : '&#x25BC;'"
  (click)="toggleUserMenu($event)"
></button>
<p-menu
  #userMenuRef
  [model]="menuItemsUser"
  [popup]="true"
  [style]="{
    'margin-top': '10px',
    'background-color': 'efefef',
    width: '362.5px'
  }"
  appendTo="body"
></p-menu>
