import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { generateJWTToken } from '../core/helpers/jwt-token.utils';

@Injectable({
  providedIn: 'root',
})
export class LocChangeLogService {
  public tableColums: any[] = [
    // {
    //   label: 'Account',
    //   enableSorting: false,
    //   sortingLable: null,
    //   fieldName: null
    // },
    {
      label: 'Store Code',
      enableSorting: true,
      sortingLable: 'location_id',
      fieldName: 'location_id',
    },
    // {
    //   label: 'Address Line 1',
    //   enableSorting: false,
    //   sortingLable: 'null',
    //   fieldName: 'null'
    // },
    // {
    //   label: 'City',
    //   enableSorting: false,
    //   sortingLable: 'null',
    //   fieldName: 'null'
    // },
    // {
    //   label: 'State',
    //   enableSorting: false,
    //   sortingLable: 'null',
    //   fieldName: 'null'
    // },
    // {
    //   label: 'Opening Date',
    //   enableSorting: false,
    //   sortingLable: 'null',
    //   fieldName: 'null'
    // },
    {
      label: 'Updated At',
      enableSorting: true,
      sortingLable: 'updated_at',
      fieldName: 'updated_at',
    },
    // {
    //   label: 'Updated By',
    //   enableSorting: false,
    //   sortingLable: 'null',
    //   fieldName: 'null'
    // },
    {
      label: 'Field name',
      enableSorting: true,
      sortingLable: 'field_name',
      fieldName: 'field_name',
    },
    {
      label: 'Old value',
      enableSorting: false,
      sortingLable: 'null',
      fieldName: 'null',
    },
    {
      label: 'New value',
      enableSorting: false,
      sortingLable: 'null',
      fieldName: 'null',
    },
  ];

  constructor(private http: HttpClient) {}

  /**
   * @description To get the location change log data.
   */
  public getLocChangeLogData(
    userId: number,
    accountId: number,
    range: string,
    paramName: string,
    page: number,
    searchQ?: string,
    sortField?: string,
    sortOrder?: string
  ): any {
    const payload = {
      iss: 'https://platform.renderseo.com',
      sub: userId, // user id
      account_id: accountId,
      iat: new Date().getTime(),
      exp: new Date().getTime() + 3 * 6000,
    };
    const jwtToken = generateJWTToken(payload, '2c4c4d06f0b35b921039d9df2725205d981ac00bf196eb71b02ce7571432e30a');
    const headers = new HttpHeaders().set('Authorization', `${jwtToken}`);
    let params = new HttpParams()
      .set(paramName, range)
      .set('account_id', accountId.toString())
      .set('limit', '100')
      .set('page', page.toString());
    if (sortField && sortOrder) {
      const sorting = `${sortField} ${sortOrder}`;
      params = params.append('order', sorting);
    }

    let url: string = `https://io.renderseo.com/location/changelogs`;
    if (searchQ) {
      url = `${url}?${params}&${searchQ}`;
    } else {
      url = `${url}?${params}`;
    }

    return this.http.get(url, { headers: headers });
  }
}
