<div class="response-wrapper">
  <div class="response-content">
    <div class="review-info">
      <div class="directory-store">
        <img
          class="directory_logo"
          [class.dir_logo_gmb]="selReview?.vendorIdent === 'gmb'"
          [class.dir_logo_yelp]="selReview?.vendorIdent === 'yelp-yelp'"
          src="assets/directories/round/{{selReview?.icon}}"
          alt="Directory logo unavailable"
        />
        <div>
          <p class="small">
            STORE CODE:
            <span
            [class.bold]="selReview.storeCode"
            [class.small]="!selReview.storeCode">
            {{ selReview.storeCode ? selReview.storeCode : 'N/A' }}
          </span>
        </p>
        </div>
      </div>
      <div class="review-details">
        <div class="full-review">
          <i>"{{ selReview.reviewerComment }}"</i>
        </div>
        <div class="review-footer">
          <hr />
          <div>
            <div class="poster-details">
              <p class="bold">{{ selReview.reviewerName }}&nbsp;</p>
              <p>rated this location&nbsp;</p>
              <p-rating [ngModel]="selReview.starRating" readonly="true" stars="5" [cancel]="false"></p-rating>
              <p>&nbsp;on&nbsp;</p>
              <p class="bold" *ngIf="selReview.reviewCreatedAt !== null">
                {{
                  selReview.reviewCreatedAt._year_ +
                    '/' +
                    selReview.reviewCreatedAt._month_ +
                    '/' +
                    selReview.reviewCreatedAt.day
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="review-reply">
      <!-- Response library goes here.. -->
      <div class="templates">
        <div *ngIf="loadResponseTemplate">
          <p class="templates_text">Loading response templates...</p>
          <p-progressSpinner styleClass="w-2rem h-2rem" strokeWidth="2" fill="var(--surface-ground)"
            animationDuration="spinnerAnimationDuration">
          </p-progressSpinner>
        </div>
        <div *ngIf="!reviewResponsesList.length && !loadResponseTemplate">
          <p class="templates_text">No templates available for this review.</p>
        </div>
        <div *ngIf="reviewResponsesList.length">
          <ng-container *ngFor="let responseText of reviewResponsesList">
            <div class="templates_btn" (click)="selectReviewText(responseText)">{{responseText}}</div>
          </ng-container>
        </div>
      </div>

      <div class="comment_box">
        <textarea pInputTextarea [(ngModel)]="selReview.replyComment" style="resize: none" maxlength="4000"
          placeholder="Type your response here..."></textarea>
        <div class="counter">{{ selReview.replyComment ? selReview.replyComment.length : 0 }} of 4000 characters</div>
      </div>

    </div>
  </div>
  <div class="response-footer">
    <button pButton label="CANCEL" class="p-button-outlined p-button-secondary" (click)="cancelSingleReply()"></button>
    <button pButton label="PUBLISH" [disabled]="!selReview.replyComment" (click)="confirmReply()"></button>
  </div>
</div>
