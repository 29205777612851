import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {IAccount} from 'src/app/acct-comps/accounts.interfaces';
import {AcctHelpers} from 'src/app/acct-comps/helpers';
import {DBService} from 'src/app/core/backend-adapter/db.service';
import {SessionService} from 'src/app/core/backend-adapter/session.service';
import {ILocation} from 'src/app/loc-comps/locations.interfaces';
import {VendorKeysType, VENDORS_KEYS} from '../directory.constants';
import {fixVendorName, mapVendorNames} from '../directory.helpers';
import {IDirectory} from '../directory.interfaces';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DirectoryService } from '../directory.service';

@Component({
  selector: 'app-directory-page',
  templateUrl: './directory-page.component.html',
  styleUrls: ['./directory-page.component.scss'],
})
export class DirectoryPageComponent implements OnInit, OnChanges, OnDestroy {
  @Input() set account(account: IAccount) {
    if (account) {
      this.currentAccount = account;
      const { global } = account;
      if (typeof global === 'string') {
        this.flatGlobal = this.acctHelpers.flattenGlobal(JSON.parse(global));
      } else {
        this.flatGlobal = this.acctHelpers.flattenGlobal(account.global);
      }
    }
  }
  @Input() set location(location: ILocation) {
    this.currentLocation = location;
  }
  @Input() disableEdit: boolean;
  @Input() filterPublished: boolean;

  private vendors = this.acctHelpers.vendors;
  private VENDOR_NAMES = mapVendorNames(this.vendors);
  private flatGlobal: {};
  private ngUnsubscribe$ = new Subject();
  private accountId: number;
  private userId: number;
  private locationId: number;

  public currentLocation: ILocation;
  public directories: IDirectory[];
  public currentAccount: IAccount;
  public catsList: any[] = [];

  activeDirs: {} = {};
  newDirectories: any[] = [];

  constructor(
    public sessionService: SessionService,
    public dbService: DBService,
    private acctHelpers: AcctHelpers,
    private directoryService: DirectoryService
  ) {}

  ngOnInit(): void {
    this.getCurrentAccountDetails();
    this.getCurrentUserDetails();
    this.getLocatioCategories();

  }

  ngOnChanges(c: SimpleChanges) {
    this.locationId = c['location'].currentValue['_id'];
    this.getActiveDirectories(c);

    if (c.account.currentValue && c.location.currentValue === null) {
      this.buildAccountDirectories((d: IDirectory[]) => (this.directories = d));
    }

    if (c.account.currentValue && c.location.currentValue !== null) {
      const locationsVendors = c.location.currentValue.vendor;

      this.buildAccountDirectories((accountDirectories: IDirectory[]) => {
        this.directories = Object.entries(locationsVendors).reduce((acc, [k, v]: [VendorKeysType, any]) => {
          k = fixVendorName(k);
          if (k !== null) {
            // skip invalid vendors
            const accountVendor = accountDirectories.find(({ vendor }) => vendor === k) || {};
            const directory: IDirectory = { ...accountVendor, ...v };
            if (typeof directory.publish === 'boolean') {
              acc.push(directory);
            }
          }
          return acc;
        }, []);
      });
    }
  }

  private getActiveDirectories(c: SimpleChanges): void {
    const global =
      typeof c.account.currentValue?.global === 'string'
        ? JSON.parse(c.account.currentValue?.global)
        : c.account.currentValue?.global;
    Object.keys(global).forEach((key) => {
      if (global[key].publish) {
        this.activeDirs[key] = global[key];
      }
    });
    const { vendor = {}, vendorStatus = {} } = c.location.currentValue;

    Object.keys(this.activeDirs).forEach((key) => {
      for (let [k, v] of Object.entries(vendor)) {
        const newKey = fixVendorName(k as VendorKeysType);
        if (key === newKey) {
          if (typeof v === 'object') {
            this.activeDirs[key] = {
              ...this.activeDirs[key],
              ...v,
              vendor: key as VendorKeysType,
              _vendorName: this.VENDOR_NAMES[key],
            };
          }
        } else {
          this.activeDirs[key] = {
            ...this.activeDirs[key],
            vendor: key as VendorKeysType,
            _vendorName: this.VENDOR_NAMES[key],
          };
        }
      }

      for (let [k, v] of Object.entries(vendorStatus)) {
        const key1 = fixVendorName(k as VendorKeysType);
        if (key === key1) {
          if (typeof v === 'object') {
            this.activeDirs[key] = { ...this.activeDirs[key], ...v };
          }
        }
      }
    });

    Object.keys(this.activeDirs).forEach((k, v) => {
      this.newDirectories.push({
        ...this.activeDirs[k],
        isLoading: true
      });
    });
  }

  // populate list of categories per vendor (codes replaced with full objects)
  private buildAccountDirectories(callback: (accountDirectories: IDirectory[]) => void): void {
    this.getActiveCats().then(
      (_) => {
        this.dbService.CategoryType.fillCatsByVendor();
        // TODO: refactor these unused cycles
        for (let vendor of this.vendors) {
          if (vendor == 'localeze' || vendor == 'here') {
            continue;
          }
          // TODO: need fixing on backend
          vendor = fixVendorName(vendor);
          const key = `${vendor}.categories`;
          if (!Array.isArray(this.flatGlobal[key])) {
            this.flatGlobal[key] = [];
          }

          for (const i of this.flatGlobal[key]) {
            const code = this.flatGlobal[key][i];
            this.flatGlobal[key][i] = this.dbService.CategoryType.findByCodeForVendor(code, vendor);
          }
        }

        let directories = this.mapVendorsToArray();
        if (this.filterPublished) {
          directories = directories.filter((v) => v.publish);
        }
        this.directories = directories;
        callback(directories);
      },
      (err) => console.log('Categ objects load fail', err)
    );
  }

  private getActiveCats(): Promise<any> {
    return this.dbService.CategoryType.loadObjects({ where: this.getCollectionOfActiveCategoriesForAccount() });
  }

  private mapVendorsToArray() {
    return Object.entries(this.flatGlobal).reduce((acc: IDirectory[], [key, value]: [string, any]) => {
      const [vendorKey, prop] = key.split('.');
      const vendorIdx = acc.findIndex((a) => a.vendor === vendorKey);

      if (vendorIdx !== -1) {
        acc[vendorIdx] = {
          ...acc[vendorIdx],
          vendor: vendorKey as VendorKeysType,
          _vendorName: this.VENDOR_NAMES[vendorKey],
          [prop]: value,
        };
      } else {
        acc.push({ vendor: vendorKey, [prop]: value } as IDirectory);
      }

      return acc;
    }, []);
  }

  // buildWhereClause
  private getCollectionOfActiveCategoriesForAccount(): string {
    const whereClauses = [];

    for (let vendor of this.vendors) {
      // TODO: need fixing on backend
      vendor = fixVendorName(vendor);
      // debugger;
      const codes = this.flatGlobal[`${vendor}?.categories`];

      if (codes && codes.length > 0) {
        const catsWquotes = [];

        for (const code of codes) {
          catsWquotes.push(`'${code}'`);
        }

        whereClauses.push(`vendorIdent = '${vendor}' AND code IN (${catsWquotes.join(',')})`);
      }
    }

    // if no clauses were built, create a where clause that returns nothing (vs everthing)
    if (whereClauses.length == 0) {
      whereClauses.push('false');
    }

    return whereClauses.join(' OR ');
  }

  /**
   * @description: Get the current account details.
   */
  private getCurrentAccountDetails(): void {
    this.sessionService
      .getSelectedAccount$()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((account: IAccount) => {
        this.accountId = account?._id;
      });
  }

  /**
   * @description: Get the current user details.
   * @returns: void
   * @arguments: void
   */
  private getCurrentUserDetails(): void {
    this.sessionService
      .getCurrentUser$()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((user) => {
        this.userId = user?.login?._id;
      });
  }

  getLocatioCategories() {
    if (this.locationId && this.accountId && this.userId) {
      this.directoryService
        .getLocationCategories(this.locationId, this.accountId, this.userId)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(
          (categories) => {
            if (Object.keys(categories).length) {
              Object.keys(categories).forEach((vendor: VendorKeysType) => {
                if (VENDORS_KEYS.includes(vendor)) {
                  if (categories[vendor]?.hasOwnProperty('locations')) {
                    this.catsList.push({
                      vendor: this.setDirName(vendor),
                      categories: categories[vendor]?.locations[0]?.categories?.map((c) => {
                        if(c['name'] !== null || c['name'] !== undefined) {
                          return c['name'];
                        }
                      }),
                    });
                  }

                  if (categories[vendor]?.hasOwnProperty('categories')) {
                    this.catsList.push({
                      vendor: this.setDirName(vendor),
                      categories: categories[vendor]?.categories?.map((c) => {
                        if(c['name'] !== null || c['name'] !== undefined) {
                          return c['name'];
                        }
                      }),
                    });
                  }
                }
              });

              this.newDirectories.forEach(dir => {
                this.catsList.forEach((cat) => {
                  if(dir['vendor'] === cat['vendor']) {
                    dir['cats'] = cat['categories']?.length && cat['categories'].filter(c => c) || [];
                    dir['isLoading'] = false;
                  }
                })
              });
            }
          },
          (err) => {
            this.catsList = [];
          },
          () => {
            this.catsList = [];
          }
        );
    };
  }

  private setDirName(vendor: string): string {
    if(vendor === 'factual') {
      return 'foursquare';
    }
    if(vendor === 'applemaps') {
      return 'appleMaps';
    }
    return vendor;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
