import * as CryptoJS from 'crypto-js';

export const generateJWTToken = (payload: any, secret: string): string => {
  var secret = secret;
  let token: any = encodeToken(payload);

  var signature: any = CryptoJS.HmacSHA256(token, secret);
  signature = base64url(signature);

  var signedToken = token + '.' + signature;
  return signedToken;
};

const base64url = (source: any): string => {
  let encodedSource = CryptoJS.enc.Base64.stringify(source);

  encodedSource = encodedSource.replace(/=+$/, '');

  encodedSource = encodedSource.replace(/\+/g, '-');
  encodedSource = encodedSource.replace(/\//g, '_');

  return encodedSource;
};

const encodeToken = (payload: any): string => {
  var header = {
    alg: 'HS256',
    typ: 'JWT',
  };

  var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
  var encodedHeader = base64url(stringifiedHeader);

  var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(payload));
  var encodedData = base64url(stringifiedData);

  var token = encodedHeader + '.' + encodedData;
  return token;
};


// function base64url(source: any) {
//   let encodedSource = CryptoJS.enc.Base64.stringify(source);

//   encodedSource = encodedSource.replace(/=+$/, '');

//   encodedSource = encodedSource.replace(/\+/g, '-');
//   encodedSource = encodedSource.replace(/\//g, '_');

//   return encodedSource;
// }


// function encodeToken(payload:any) {
//   var header = {
//     "alg": "HS256",
//     "typ": "JWT"
//   };

//   var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
//   var encodedHeader = base64url(stringifiedHeader);

//   var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(payload));
//   var encodedData = base64url(stringifiedData);

//   var token = encodedHeader + "." + encodedData;
//   return token;
// }


// function signToken(payload:any,key:string) {
//   var secret = key;
//   let token:any = encodeToken(payload);

//   var signature:any = CryptoJS.HmacSHA256(token, secret);
//   signature = base64url(signature);

//   var signedToken = token + "." + signature;
//   return signedToken;
// }
