import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { IAccount } from '../acct-comps/accounts.interfaces';
import { AccountsService } from '../acct-comps/accounts.service';
import { ICollectionWrapper } from '../core/backend-adapter/api/api.interfaces';
import { SessionService } from '../core/backend-adapter/session.service';
import { REVIEW_TABS } from './review-management.constants';
import { ReviewsService } from './reviews.service';
import { IDates } from '../core/feature-modules/table-filter/table-filter.interfaces';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-review-management',
  templateUrl: './review-management.component.html',
  styleUrls: ['./review-management.component.scss'],
})
export class ReviewManagementComponent implements OnInit, OnDestroy {
  reviewTabs: typeof REVIEW_TABS = REVIEW_TABS;

  // tab menu
  tabItems: MenuItem[] = [];
  activeItem: MenuItem;
  private ngUnsubscribe$ = new Subject();
  /** Hide/display Google, Yelp and Other reviews tabs based on status  */
  private reviewsTabFlags = {
    showGoogleReviewsTab: true,
    showYelpReviewsTab: false,
    showOtherReviewsTab: false,
  };

  public actionItems = [
    {
      label: 'Last 120 days',
      command: () => {
        this.exportLast120DaysReviews();
      },
    },
  ];

  displayFilters: boolean = false;
  downloadFileType: string = 'csv';
  dateCategories: any[] = [
    { name: 'Last 30 days', key: '30', value: '_day_30' },
    { name: 'Last 120 days', key: '120', value: '_day_120' },
    { name: 'Last 12 months', key: '12', value: '_month_12' },
    { name: 'Custom', key: 'c', value: 'c' },
  ];
  selectedDate: string;
  customDates: IDates = {
    start: null,
    end: null,
  };
  customStartDate: string;
  customEndDate: string;

  constructor(
    private sessionService: SessionService,
    private reviewsService: ReviewsService,
    private accountsService: AccountsService
  ) {}

  ngOnInit(): void {
    this.getCurrAccountDetails();
  }

  private getCurrAccountDetails(): void {
    let allAccounts: IAccount[] = [];
    this.accountsService
      .getAccounts()
      .pipe(
        switchMap((data: ICollectionWrapper<IAccount[]>) => {
          allAccounts = this.mapAccountsWithSubaccounts(data.collection);
          return this.sessionService.getSelectedAccount$();
        }),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((res: IAccount) => {
        const filteredAcc = allAccounts.find((acc: IAccount) => res?._id === acc?._id);
        this.setAccountDetails(filteredAcc);
      });
  }

  private initReviewTabs(): void {
    this.tabItems = Object.values(this.reviewTabs).map((tabName: string) => {
      return {
        label: tabName,
        disabled:
          // tabName === this.reviewTabs.PENDING ||
          (tabName === this.reviewTabs.GOOGLE && !this.reviewsTabFlags.showGoogleReviewsTab) ||
          // (tabName === this.reviewTabs.YELP && !this.reviewsTabFlags.showYelpReviewsTab) ||
          (tabName === this.reviewTabs.OTHER_REVIEWS && !this.reviewsTabFlags.showOtherReviewsTab),
        hash: tabName.split(' ').join('-').toLowerCase(),
        command: (event) => {
          this.setActiveTab(event.item);
        },
      };
    });
    this.activeItem = this.tabItems[0];
  }

  // tab menu navigation
  setActiveTab(item: MenuItem) {
    this.activeItem = item;
  }

  private setAccountDetails(res: IAccount): void {
    const { _subAccounts: subAccounts = undefined } = res;
    if (subAccounts) {
      const account: IAccount =
        subAccounts &&
        subAccounts?.length &&
        subAccounts.find((subAcc) => {
          const globalObject = this.parseGlobalObject(subAcc);
          const { yelp, lde } = globalObject;
          if (yelp?.showReviews || lde?.showReviews) {
            return subAcc;
          }
        });

      if (account) {
        const globalObj = this.parseGlobalObject(account);
        this.reviewsTabFlags = {
          showGoogleReviewsTab: (globalObj?.gmb && globalObj?.gmb?.showReviews) || true,
          showYelpReviewsTab: (globalObj?.yelp && globalObj?.yelp?.showReviews) || false,
          showOtherReviewsTab: (globalObj?.lde && globalObj?.lde?.showReviews) || false,
        };
      }
    } else {
      const newObject = this.parseGlobalObject(res);
      this.reviewsTabFlags = {
        showGoogleReviewsTab: (newObject?.gmb && newObject?.gmb?.showReviews) || true,
        showYelpReviewsTab: (newObject?.yelp && newObject?.yelp?.showReviews) || false,
        showOtherReviewsTab: (newObject?.lde && newObject?.lde?.showReviews) || false,
      };
    }
    this.initReviewTabs();
  }

  private parseGlobalObject(globalObject) {
    const result = typeof globalObject?.global === 'string' ? JSON.parse(globalObject?.global) : globalObject?.global;
    return result;
  }

  public exportLast120DaysReviews(): void {
    this.reviewsService.exportAllReviews();
  }

  private mapAccountsWithSubaccounts(collection): IAccount[] {
    const myCollection = collection.map((x) => Object.assign({}, x));
    const accountsTree = myCollection.reduce((acc, v: IAccount, i, array) => {
      const hasParentAccount = v._parent_id;
      if (hasParentAccount) {
        const currentlyAdded = acc.find((a) => a._id === v._parent_id);
        const parentAccount = array.find((a) => a._id === v._parent_id) || {};
        if (!parentAccount?.hasOwnProperty('_subAccounts')) parentAccount['_subAccounts'] = [];

        if (currentlyAdded) {
          currentlyAdded._subAccounts.push(v);
          currentlyAdded.hasSubAccts = true;
        } else {
          parentAccount['_subAccounts'].push(v);
          parentAccount['hasSubAccts'] = true;
          acc.push(parentAccount);
        }
      } else {
        const currentlyAdded = acc.find((a) => a._id === v._id);
        if (!currentlyAdded) {
          acc.push(v);
        }
      }
      return acc;
    }, []);

    const flattenAccounts = accountsTree.reduce((acc, v) => {
      if (v.hasOwnProperty('_subAccounts')) {
        acc.push(v);
        acc.push(...v._subAccounts.sort((a, b) => a.name.localeCompare(b.name)));
      } else {
        acc.push(v);
      }
      return acc;
    }, []);
    return flattenAccounts;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  openFilters() {
    this.displayFilters = true;
  }

  closeFilters() {
    this.displayFilters = false;
  }

  /**
   * @description To save the selected filters and export the reviews.
   * @returns void
   */
  saveFilters(): void {
    this.displayFilters = false;
    const params =
      this.selectedDate !== 'c'
        ? `${this.selectedDate}?format=csv`
        : `_period_${this.customStartDate}_${this.customEndDate}?format=csv`;
    this.reviewsService.exportReviewsNew(params, this.downloadFileType);
  }

  selectReportType(downloadFileType: string) {
    this.downloadFileType = downloadFileType;
  }

  onDateChange(date): void {
    this.selectedDate = date;
  }

  /**
   * @description To get the selected custom date.
   * @param period Is the start or end selected date.
   */
  public customDate(period: string): void {
    if (period === 'start') {
      this.customStartDate = formatDate(this.customDates.start, 'yyyy-MM-dd', 'en-US').replace(/-/g, '');
    } else {
      var followingDay = new Date(this.customDates.end.getTime() + 86400000);
      this.customEndDate = formatDate(followingDay.toLocaleDateString(), 'yyyy-MM-dd', 'en-US').replace(/-/g, '');
    }
  }
}
