<div class="post-table-container">
  <p-table
    #gbpPostTable
    [value]="allPostsData"
    [loading]="postLoading"
    sortMode="single"
    [(selection)]="selectedRows"
    [globalFilterFields]="['summary', 'topicType']"
    dataKey="_id"
    scrollable="true"
    [scrollHeight]="'calc(100vh - 407px)'"
    rowExpandMode="single"
    responsive="true"
    [paginator]="true"
    [lazy]="true"
    [rows]="pageSize"
    (onLazyLoad)="lazyLoadData($event)"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
    [showCurrentPageReport]="true"
    [totalRecords]="totalPostsCount"
  >
    <ng-template pTemplate="caption">
      <div class="flex-search-bar">
        <div class="search-bar-buttons">
          <div class="acct-search">
            <span class="p-input-icon-right">
              <i class="pi pi-search"></i>
              <span class="p-inputgroup">
                <input
                  class="input"
                  pInputText
                  type="text"
                  placeholder="Search by Store Code..."
                  [(ngModel)]="keyword"
                  (input)="gbpPostTable.filterGlobal($event.target.value, 'contains')"
                />
                <button
                  pButton
                  type="button"
                  icon="fa fa-filter"
                  class="filter-button"
                  [ngStyle]="chipsArray.length > 0 && { color: SYSTEM_COLORS.$systemGreen }"
                  (click)="op.toggle($event)"
                ></button>
                <p-overlayPanel #op>
                  <ng-template pTemplate>
                    <div class="search-filter">
                      <app-table-filter [data]="filterData" (filterData)="onChipsRefresh($event)"></app-table-filter>
                    </div>
                  </ng-template>
                </p-overlayPanel>
              </span>
            </span>
          </div>
          <div class="button-div-flex" [pTooltip]="createPostTooltip()">
            <button
              pButton
              label="CREATE POST"
              class="p-button-primary"
              iconPos="left"
              icon="pi pi-plus-circle"
              (click)="openPostCreate()"
              [disabled]="accountIsNotLeaf || !checkAddPerms"
            ></button>
          </div>
        </div>
      </div>
      <div class="chips-bar">
        <p-chip *ngFor="let chipText of chipsArray" [label]="chipText"></p-chip>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr *ngIf="allPostsData">
        <th *ngIf="false" class="p-selectable-row">SELECT</th>
        <th class="smaller-columns" pSortableColumn="_id">ID <p-sortIcon field="_id"></p-sortIcon></th>
        <th>IMAGES</th>
        <th pSortableColumn="summary">POST<p-sortIcon field="summary"></p-sortIcon></th>
        <th pSortableColumn="topicType">POST TYPE<p-sortIcon field="topicType"></p-sortIcon></th>
        <th>LOCATIONS</th>
        <th>ACTIVITY</th>
        <th>ACTIONS</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td *ngIf="false" class="p-selectable-row">
          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </td>
        <td class="smaller-columns">
          {{ rowData._id }}
        </td>
        <td>
          <img
            src="{{ rowData.internalURL || rowData.sourceURL }}"
            (error)="rowData.sourceURL = errorImg && (rowData.internalURL = errorImg)"
            class="table-photo"
          />
        </td>
        <td>
          {{ rowData.summary }}
        </td>
        <td>{{ rowData.topicType }}</td>
        <!-- <td class="table-impressions">
          <ng-container *ngIf="postTrends">
            <div class="table-impressions-row">
              <span class="material-icons-outlined"> mouse </span>
              <span class="table-impressions-row-text">{{ postTrends?.localPostActionsCTA }}</span>
            </div>
            <div class="table-impressions-row">
              <span class="material-icons-outlined"> visibility </span>
              <span class="table-impressions-row-text">{{ postTrends?.localPostViewsSearch }}</span>
            </div>
          </ng-container>
        </td> -->
        <td class="table-locations">
          Posted on
          <span class="table-locations-links" (click)="openLocations(rowData._id)"
            >{{ rowData.loc_count_live }}/{{ rowData.loc_count }} locations</span
          >
        </td>
        <td class="table-activity">
          <div class="activity-button" [ngClass]="rowData?._status | activityStatus">
            {{ rowData?._status | getStatus }}
          </div>
          <div class="edit_date">
            {{
            rowData.scheduleTime
            ? rowData.scheduleTime?.strftime('%m/%d/%Y %H:%M')
            : rowData.createTime ? rowData.createTime?.strftime('%m/%d/%Y') :
            rowData._lastUpdatedAt.strftime('%m/%d/%Y')
            }}
            <span *ngIf="(rowData?._status === activityStatus.SCHEDULED) && checkEditPerms" class="edit_date__enable" (click)="editPost(rowData)"><i
                class="pi pi-pencil"></i>
            </span>

            <span *ngIf="rowData?._status !== activityStatus.SCHEDULED" class="edit_date__disable">
              <i class="pi pi-pencil"></i>
            </span>
          </div >
        </td>
        <td class="table-actions">
          <ul class="table-actions-list">
            <li (click)="editPost(rowData)" [class.disabled]="(rowData?._status === 'AR') || !checkEditPerms">
              <i class="pi pi-pencil"></i> Edit
            </li>
            <li class="disabled" [pTooltip]="tooltipText"><i class="pi pi-map"></i> Duplicate</li>

            <li *ngIf="false"
            [ngClass]="{ disabled: rowData?._status === activityStatus.PUBLISHED }"
              (click)="unpublishPost(rowData)"
            >
              <i class="pi pi-ban"></i> Unpublish
            </li>
            <li class="disabled" [pTooltip]="tooltipText"><i class="pi pi-ban"></i> Unpublish</li>

            <li [class]="deleteBtnClass(rowData)" (click)="openPostDelete(rowData)" [class.disable_post__delete]="!checkDeletePerms">
              <i class="pi pi-trash"></i> Delete
            </li>
          </ul>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <ng-template #loading>
    <p-progressSpinner></p-progressSpinner>
  </ng-template>
</div>
