<div class="reviews-page-container">
  <!-- Main page header -->
  <div class="flex">
    <div class="header-parent-title">
      <div class="header-child-title">Your Reviews.</div>
      <div class="export_reviews">
        <p-button
          pButton
          class="export_reviews__btn"
          (click)="openFilters()"
          [class.close]="!displayFilters"
          [class.open]="displayFilters"
          >Export</p-button>
        <div *ngIf="displayFilters" class="filters_block export_reviews__filters">
          <div>
            <p class="filters_block__title">Select file format.</p>
            <div class="dwnld_rpt_block">
              <button class="dwnld_rpt" [class.active_rpt]="downloadFileType === 'csv'" (click)="selectReportType('csv')">
                CSV</button>
              <button class="dwnld_rpt" [class.active_rpt]="downloadFileType === 'xlsx'" (click)="selectReportType('xlsx')">
                XLSX</button>
            </div>
          </div>

          <div *ngFor="let date of dateCategories" class="field-checkbox">
            <label [for]="date.key" class="ml-2">
              {{ date.name }}
            </label>
            <p-radioButton [inputId]="date.key" name="date.name" [value]="date.value"
              [(ngModel)]="selectedDate"></p-radioButton>
          </div>

          <div *ngIf="selectedDate === 'c'" class="calendar">
            <div class="calendar_start">
              <p-calendar [showIcon]="true" dateFormat="mm/dd/yy" [(ngModel)]="customDates['start']"
              [readonlyInput]="true" (onSelect)="customDate('start')"></p-calendar>
            </div>
            <div class="calendar_end">
              <p-calendar [showIcon]="true" dateFormat="mm/dd/yy" [(ngModel)]="customDates['end']"
              [readonlyInput]="true" (onSelect)="customDate('end')"></p-calendar>
            </div>
          </div>
          <div class="cta_btn">
            <button class="cancel_btn" (click)="closeFilters()">Cancel</button>
            <button class="save_btn" (click)="saveFilters()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>



  <!-- Tab menu-->
  <!-- <p-tabMenu class="tabs-menu" [model]="tabItems" [activeItem]="activeItem">
    <ng-template pTemplate="item" let-item let-i="index">
      <div class="tabs-style">{{ item.label }}</div>
    </ng-template>
  </p-tabMenu> -->
  <ng-container *ngIf="tabItems.length">
    <rs-page-tabs [tabItems]="tabItems" (tabSelected)="activeItem = $event"> </rs-page-tabs>
    <!-- Google Reviews Tab -->
    <div *ngIf="activeItem.label == reviewTabs.GOOGLE"><app-google-reviews-tab></app-google-reviews-tab></div>

    <!-- Yelp Reviews Tab -->
    <div *ngIf="activeItem.label == reviewTabs.YELP"><app-yelp-reviews-tab></app-yelp-reviews-tab></div>

    <!-- Pending Responses Tab -->
    <!-- <div *ngIf="activeItem.label == reviewTabs.PENDING"><app-pending-responses-tab></app-pending-responses-tab></div> -->

    <!-- Pandio reviews tab -->
    <div *ngIf="activeItem.label == reviewTabs.OTHER_REVIEWS"><app-pandio-reviews-tab></app-pandio-reviews-tab></div>
  </ng-container>

</div>
