import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from "rxjs";
import { generateJWTToken } from "src/app/core/helpers/jwt-token.utils";

@Injectable({
  providedIn: 'root',
})
export class ReviewResponseTemplateService {
  private reviewTemplate$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public getSelectedTemplate$: Observable<any> = this.reviewTemplate$.asObservable();
  public starRatingsData: Ratings[] = [
    { label: 'One', value: 1, controlValue: '1' },
    { label: 'Two', value: 2, controlValue: '2' },
    { label: 'Three', value: 3, controlValue: '3' },
    { label: 'Four', value: 4, controlValue: '4' },
    { label: 'Five', value: 5, controlValue: '5' },
  ];
  public thumbRatingsData: Ratings[] = [
    { label: 'Up', value: 'up', controlValue: 'thumb_rating' },
    { label: 'Down', value: 'down', controlValue: 'thumb_rating' },
    { label: 'None', value: 'both', controlValue: 'thumb_rating' },
  ];

  constructor(private http: HttpClient) {}

  /**
   * @description To create a new review response template.
   * @param accountId account id.
   * @param userId user id.
   * @returns observable.
   */
  public addTemplate(payload: any, accountId: any, userId: any): Observable<any> {
    const payloadObj = {
      iss: 'https://platform.renderseo.com', // Might have to change this url....
      sub: userId, // user id
      account_id: accountId,
      iat: new Date().getTime(),
      exp: new Date().getTime() + 3 * 6000,
    };
    const jwtToken = generateJWTToken(payloadObj, '2c4c4d06f0b35b921039d9df2725205d981ac00bf196eb71b02ce7571432e30a');
    const headers = new HttpHeaders().set('Authorization', `${jwtToken}`);
    const url: string = `https://io.renderseo.com/response_template/save`;
    return this.http.post(url, payload, { headers: headers });
  }

  /**
   * @description To get the list of review response templates.
   * @param accountId account id.
   * @param userId user id.
   * @returns observable.
   */
  public getTemplateList(accountId: any, userId: any): Observable<any> {
    // const payload = {'accounts': [85, 86, 87, 88, 171, 172, 173, 175, 495]}; // Add accounts array here.
    const payloadObj = {
      iss: 'https://platform.renderseo.com', // Might have to change this url....
      sub: userId, // user id
      account_id: accountId,
      iat: new Date().getTime(),
      exp: new Date().getTime() + 3 * 6000,
    };
    const jwtToken = generateJWTToken(payloadObj, '2c4c4d06f0b35b921039d9df2725205d981ac00bf196eb71b02ce7571432e30a');
    const headers = new HttpHeaders().set('Authorization', `${jwtToken}`);
    const url: string = `https://io.renderseo.com/response_template/templates`;
    const payload = { accounts: [accountId] };
    return this.http.post(url, payload, { headers: headers });
  }

  /**
   * @descriptionTo update a ttemplate.
   * @param payload template object that needs to be updated
   * @param accountId current account Id
   * @param userId user Id
   * @returns Observable
   */
  public updateTemplate(payload, accountId: number, userId: number): Observable<any> {
    const payloadObj = {
      iss: 'https://platform.renderseo.com', // Might have to change this url....
      sub: userId, // user id
      account_id: accountId,
      iat: new Date().getTime(),
      exp: new Date().getTime() + 3 * 6000,
    };
    const jwtToken = generateJWTToken(payloadObj, '2c4c4d06f0b35b921039d9df2725205d981ac00bf196eb71b02ce7571432e30a');
    const headers = new HttpHeaders().set('Authorization', `${jwtToken}`);
    const url: string = `https://io.renderseo.com/response_template/update`;
    return this.http.post(url, payload, { headers: headers });
  }

  /**
   * @description To delete a template.
   * @param templateId template id to be deleted.
   * @param accountId account id.
   * @param userId user id.
   * @returns observable.
   */
  public deleteTemplate(templateId: any, accountId: any, userId: any): Observable<any> {
    const payloadObj = {
      iss: 'https://platform.renderseo.com', // Might have to change this url....
      sub: userId, // user id
      account_id: accountId,
      iat: new Date().getTime(),
      exp: new Date().getTime() + 3 * 6000,
    };
    const jwtToken = generateJWTToken(payloadObj, '2c4c4d06f0b35b921039d9df2725205d981ac00bf196eb71b02ce7571432e30a');
    const headers = new HttpHeaders().set('Authorization', `${jwtToken}`);
    const url: string = `https://io.renderseo.com/response_template/delete?id=${templateId}`;
    const payload = { id: templateId };
    return this.http.delete(url, { headers: headers });
  }

  /**
   * @description Gets the review template that needs to be updated/edited.
   * @returns void.
   */
  public setReviewTemplateUpdate(template: any): void {
    this.reviewTemplate$.next(template);
  }

  /**
   * @description To get the list of response templates to be used on reviews page.
   * @param accountId account id.
   * @param userId user id.
   * @returns observable.
   */
  public getResponseTemplateList(
    accountId: number,
    userId: number,
    starRating: number,
    reviewTextPresence: boolean
  ): Observable<any> {
    // const payload = {'accounts': [85, 86, 87, 88, 171, 172, 173, 175, 495]}; // Add accounts array here.
    const payloadObj = {
      iss: 'https://platform.renderseo.com', // Might have to change this url....
      sub: userId, // user id
      account_id: accountId,
      iat: new Date().getTime(),
      exp: new Date().getTime() + 3 * 6000,
    };
    const jwtToken = generateJWTToken(payloadObj, '2c4c4d06f0b35b921039d9df2725205d981ac00bf196eb71b02ce7571432e30a');
    const headers = new HttpHeaders().set('Authorization', `${jwtToken}`);
    const url: string = `https://io.renderseo.com/response_template/templates`;
    const payload = {
      accounts: [accountId],
      star_rating: starRating,
      review_text_presence: reviewTextPresence
     };
    return this.http.post(url, payload, { headers: headers });
  }
}

interface Ratings {
  label: string;
  value: number | string;
  controlValue: string;
}
