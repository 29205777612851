<div class="flex-div">
  <div class="table-container">
    <p-table
      #gbpPhotoTable
      class="gbp-photo-table"
      [value]="photoData"
      sortMode="single"
      [(selection)]="selectedRows"
      dataKey="id"
      scrollable="true"
      [scrollHeight]="'calc(100vh - 407px)'"
      rowExpandMode="single"
      responsive="true"
      [paginator]="true"
      [lazy]="true"
      [rows]="pageSize"
      (onLazyLoad)="lazyLoadData($event)"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
      [showCurrentPageReport]="true"
      [totalRecords]="totalPhotosCount"
    >
      <ng-template pTemplate="caption">
        <div class="flex-search-bar">
          <div class="search-bar-buttons">
            <div class="acct-search">
              <span class="p-input-icon-right">
                <i class="pi pi-search"></i>
                <span class="p-inputgroup">
                  <input
                    class="input"
                    pInputText
                    type="text"
                    placeholder="Search by Store Code..."
                    [(ngModel)]="keyword"
                    (input)="gbpPhotoTable.filterGlobal($event.target.value, 'contains')"
                  />
                  <button
                    pButton
                    type="button"
                    icon="fa fa-filter"
                    class="filter-button"
                    [ngStyle]="chipsArray.length > 0 && { color: SYSTEM_COLORS.$systemGreen }"
                    (click)="op.toggle($event)"
                  ></button>
                  <p-overlayPanel #op>
                    <ng-template pTemplate>
                      <div class="gbp-search-filter">
                        <app-table-filter [data]="filterData" (filterData)="onChipsRefresh($event)"></app-table-filter>
                      </div>
                    </ng-template>
                  </p-overlayPanel>
                </span>
              </span>
            </div>
            <div class="button-div-flex" [pTooltip]="createMediaTooltip()">
              <p-splitButton
                #actions
                label="UPLOAD MEDIA"
                styleClass="export-button"
                [model]="actionItems"
                (onClick)="actions.onDropdownButtonClick(null)"
                [disabled]="accountIsNotLeaf || !checkAddPerms">
                <!-- Ask about leaf account. -->
              </p-splitButton>
            </div>
          </div>
        </div>
        <div class="flex-chips-bar">
          <p-chip *ngFor="let chipText of chipsArray" [label]="chipText"></p-chip>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th *ngIf="false" class="p-selectable-row">SELECT</th>
          <th class="smaller-columns" pSortableColumn="_id">ID <p-sortIcon field="_id"></p-sortIcon></th>
          <th>IMAGE</th>
          <th pSortableColumn="category">CATEGORY <p-sortIcon field="category"></p-sortIcon></th>
          <th >LOCATIONS</th>
          <th *ngIf="false" pSortableColumn="optimization">OPTIMIZATION
            <p-sortIcon field="optimization"></p-sortIcon>
          </th>
          <th>ACTIVITY</th>
          <th>ACTIONS</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td *ngIf="false" class="p-selectable-row">
            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
          </td>
          <td class="smaller-columns">
            {{ rowData._id }}
          </td>
          <td>
            <img *ngIf="!rowData.isVideo" src="{{ rowData.internalURL || rowData.sourceURL }}" (click)="openPhotoDetails(rowData)"
              class="gbp-images-photo" />
            <video *ngIf="rowData.isVideo" width="177" height="100%" controls>
              <source [src]="rowData.internalURL" type="video/mp4">
            </video>
          </td>
          <td>
            {{ rowData.category }}
          </td>
          <td class="gbp-locations-body">
            Posted on
            <span class="gbp-location-links" (click)="openPhotoLocations(rowData._id)">{{ rowData.loc_count_live }}
              /{{ rowData.loc_count }} locations</span>
          </td>
          <td *ngIf="false">
            <div
              [pTooltip]="
                rowData._status == activityStatus.UNPUBLISHED
                  ? 'Photos can only be optimized once published or scheduled'
                  : null
              "
              tooltipPosition="bottom"
              class="gbp-optimization-wrapper"
            >
              <button
                pButton
                [label]="rowData.optimized ? 'RE-OPTIMIZE' : 'OPTIMIZE'"
                class="p-button-outlined p-button-sm gbp-optimization-button"
                [disabled]="rowData._status == activityStatus.UNPUBLISHED"
                (click)="openPhotoOptimize(rowData)"
              ></button>
              <span class="material-icons-outlined check-icon" [class.icon-disabled]="!rowData.optimized"> check_circle </span>
            </div>
          </td>
          <td class="gbp-activity-body">
            <div
              class="activity-button"
              (click)="rowData._status == activityStatus.UNPUBLISHED && openPhotoDetails(rowData, true)"
              [ngClass]="rowData._status | activityStatus"
            >
              {{ rowData._status | getStatus }}
            </div>
            {{
            rowData.scheduleTime
              ? rowData.scheduleTime?.strftime('%m/%d/%Y %H:%M')
              : rowData.createTime ? rowData.createTime?.strftime('%m/%d/%Y') :
                rowData._lastUpdatedAt.strftime('%m/%d/%Y')
            }}
          </td>
          <td class="gbp-actions-body">
            <ul class="gbp-actions-list">
              <li
                class="disabled"
                [pTooltip]="tooltipText"
                *ngIf="rowData._status == activityStatus.UNPUBLISHED"
              >
                <i class="pi pi-pencil"></i>
                Schedule/Publish
              </li>
              <li
                (click)="editPhotoDate(rowData)"
                *ngIf="(rowData._status === activityStatus.SCHEDULED)"
                [class.disable_photo__edit]="!checkEditPerms">
                <i class="pi pi-pencil"></i>
                Edit
              </li>
              <li *ngIf="false" class="disabled" [pTooltip]="tooltipText"><i class="pi pi-map"></i> View EXIF</li>
              <li *ngIf="false" class="disabled" [pTooltip]="tooltipText" ><i class="pi pi-clone"></i> Duplicate</li>
              <li class="disabled" [pTooltip]="optimizeTooltipText(rowData)" (click)="openPhotoOptimize(rowData)"><i class="pi pi-star"></i> Optimize</li>
              <li
                [class]="deleteBtnClass(rowData)"
                (click)="openPhotoDelete(rowData)"
                [class.disable_photo__delete]="!checkDeletePerms"
                ><i class="pi pi-trash"></i> Delete</li>
            </ul>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
