import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {faFacebookF, faInstagram, faLinkedinIn, faTwitter} from '@fortawesome/free-brands-svg-icons';
import {faCheckCircle, faCircle, faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {RecentAccountsService} from 'src/app/acct-comps/recent-accounts.service';
import {CookieService} from 'src/app/core/backend-adapter/cookie.service';
import {STORAGE_KEYS, StorageService} from 'src/app/core/backend-adapter/storage.service';
import {isUserAdmin} from 'src/app/core/permissions/permissions.utils';

import {DBService} from '../../core/backend-adapter/db.service';
import {SessionService} from '../../core/backend-adapter/session.service';
import {SocketService} from '../../core/backend-adapter/socket.service';
import {IAuthenticateData} from './../../core/backend-adapter/session.interfaces';
import {LoginService} from './../login.service';
import {StyleChangerService} from '@app/core/feature-modules/whitelabel/style-changer/style-changer.service';
import {RsThemes} from '@app/core/feature-modules/whitelabel/style-changer/style-changer.interfaces';
import { AwsSecretService } from '../shared/services/aws-secret.service';
import { from } from 'rxjs';
import {
  GetSecretValueCommand,
  SecretsManagerClient,
} from "@aws-sdk/client-secrets-manager";

declare var location: any;

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  selectedValues: string[] = ['val1', 'val2'];
  loginId = '';
  logging: boolean;
  password: string;
  passwordType = 'password';

  errMessage: string;
  serverName: string;

  resetPwdFlow = false;
  pwdResetEmail = '';
  resetStep = 'input-email';

  // Pass change vars
  newPass = '';
  confirmPass = '';
  errMsg: string;
  haveErr = true;

  // fontawesome icons
  facebook = faFacebookF;
  linkedin = faLinkedinIn;
  instagram = faInstagram;
  twitter = faTwitter;
  exclamation = faExclamationCircle;
  public wasRecentlyLogged = this.storageService.get(STORAGE_KEYS.wasRecentlyLogged);
  public isWhitelabeled = false;

  constructor(
    public dbService: DBService,
    public sessionService: SessionService,
    public socketService: SocketService,
    private router: Router,
    private cookieService: CookieService,
    private loginService: LoginService,
    private storageService: StorageService,
    private recentAccountsService: RecentAccountsService,
    private styleChangerService: StyleChangerService,
    private awsSecretService: AwsSecretService
  ) {
  }

  ngOnInit(): void {
    this.serverName = location.host;
    if (this.sessionService.isLoggedIn()) {
      this.router.navigateByUrl('/dashboard');
    }

    this.styleChangerService.getCurrentTheme().subscribe((theme: RsThemes) => {
      this.isWhitelabeled = (theme == RsThemes.whitelabeled);
    });
  }

  // password validations
  iconForRule(ruleFunc, input?) {
    if (!input) {
      return faCircle;
    }

    if (typeof ruleFunc != 'function') {
      const val = ruleFunc;
      ruleFunc = () => val;
    }

    return ruleFunc(input) ? faCheckCircle : faExclamationCircle;
  }

  styleForRule(ruleFunc, input?) {
    if (!input) {
      return 'neutralRule';
    }

    if (typeof ruleFunc != 'function') {
      const val = ruleFunc;
      ruleFunc = () => val;
    }

    return ruleFunc(input) ? 'passedRule' : 'failedRule';
  }

  hasRequiredLength(input) {
    return input.length >= 8;
  }

  requiresLetter(input) {
    return input.match(/[A-Z]/i);
  }

  requiresDigit(input) {
    return input.match(/[0-9]/i);
  }

  passwordsMatch() {
    if (this.newPass !== this.confirmPass) {
      this.haveErr = true;
      return false;
    }

    this.haveErr = false;
    return true;
  }

  passStyle(newP, confirmP) {
    if ((!newP && !confirmP) || !confirmP) {
      return 'neutralRule';
    }

    return newP == confirmP ? 'passedRule' : 'failedRule';
  }

  passIcon(newP, confirmP) {
    if ((!newP && !confirmP) || !confirmP) {
      return faCircle;
    }

    return newP == confirmP ? faCheckCircle : faExclamationCircle;
  }

  validateInput(input) {
    this.errMessage = null;
    this.hasRequiredLength(input);
    this.requiresLetter(input);
    this.requiresDigit(input);

    !input ||
    !this.hasRequiredLength(input) ||
    !this.requiresLetter(input) ||
    !this.requiresDigit(input) ||
    !this.passwordsMatch()
      ? (this.haveErr = true)
      : (this.haveErr = false);
  }

  // reset flow
  changePassword() {
    console.log('Password reset requested');
    this.resetStep = 'req-sent';

    // this.socketService.sendRequest('change-password', {
    //   loginId: this.pwdResetEmail,
    //   oldPassword: this.oldPass,
    //   newPassword: this.newPass,
    // }
    // )
    //   .then(
    //     (args) => {
    //       this.messageService.add({
    //         severity: 'info',
    //         summary: 'Password changed', detail: 'Your password has been successfully updated.',
    //         life: 3000, closable: true
    //       });
    //     },
    //     (errArgs) => {
    //       this.errMsg = errArgs.message;
    //     }
    //   );
  }

  sendResetEmail() {
    console.log('Email reset requested');
    this.resetStep = 'email-sent';
  }

  backToLogin() {
    this.resetPwdFlow = false;
    this.resetStep = 'input-email';
    this.pwdResetEmail = '';
  }

  // reset(): void {
  //   this.loginId = '';
  //   this.password = '';
  // }

  // regular login flow
  inputsValid() {
    return this.loginId && this.loginId.length && this.password && this.password.length;
  }

  toggleShowHide() {
    if (this.passwordType == 'password') {
      this.passwordType = 'text';
    } else {
      this.passwordType = 'password';
    }
  }

  login() {
    this.errMessage = '';
    this.logging = true;
    this.loginService.login({loginId: this.loginId, password: this.password}).subscribe(
      (res: IAuthenticateData) => {
        if (res.status == 'ok') {
          this.cookieService.setAuthCookie(res.authId);
          this.dbService.loadAll().then((_) => {
            res.login.authz = JSON.parse(res.login.authz as any);

            // Commenting it for now.
            // this.sessionService.setupAuthValues(res);
            // this.sessionService.setCurrentUser$(res);
            /* Disabled routing to '/accounts' page when user's an Admin. Now we redirect to '/dashboard' */
            this.router.navigate(['/dashboard']);
          });
        } else {
          this.cookieService.setAuthCookie('');
          this.errMessage = (res as any).message;
          this.logging = false;
        }
      },
      (err) => {
        this.cookieService.setAuthCookie('');
        this.errMessage = err.message;
        this.logging = false;
      }
    );
  // this.getData();
  }

  async getData() {
   const secretName = `prod/api/authorizer/jwt`;
    const client = new SecretsManagerClient({
      region: 'us-east-1',
      // accessKeyId: 'AKIASNONIBOU54UW47VP',
      // secretAccessKey: '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',
     });
    const response = await client.send(
      new GetSecretValueCommand({
        SecretId: secretName,

      }),
    );
    console.log(response);
  }

  navigateToForm() {
    window.open('https://renderseo.atlassian.net/servicedesk/customer/portals', '_blank');
  }
}
