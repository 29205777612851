<div class="gbp-qa-owner-view-wrapper">
  <p-table
    #gbpQaTable
    [value]="allQuestions"
    [loading]="loading"
    sortMode="single"
    [globalFilterFields]="['questionText', 'answerText', 'ans_count_cust']"
    dataKey="_id"
    scrollable="true"
    [scrollHeight]="'calc(100vh - 477px)'"
    rowExpandMode="single"
    [lazy]="true"
    [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
    (onLazyLoad)="lazyLoadQuestions($event)"
    [rows]="pageSize"
    [totalRecords]="totalQuestionsCount"
    [(selection)]="selectedRows"
    [showCurrentPageReport]="true"
  >
    <ng-template pTemplate="caption">
      <div class="flex-search-bar">
        <div class="search-bar-buttons">
          <div class="acct-search">
            <span class="p-input-icon-right">
              <i class="pi pi-search"></i>
              <span class="p-inputgroup">
                <input
                  class="input"
                  pInputText
                  type="text"
                  placeholder="Search by question text or answer text..."
                  [(ngModel)]="keyword"
                  (input)="gbpQaTable.filterGlobal($event.target.value, 'contains')"
                />
                <button
                  pButton
                  type="button"
                  icon="fa fa-filter"
                  class="filter-button"
                  [ngStyle]="chipsArray.length > 0 && { color: SYSTEM_COLORS.$systemGreen }"
                  (click)="op.toggle($event)"
                ></button>
                <p-overlayPanel #op>
                  <ng-template pTemplate>
                    <div class="search-filter">
                      <app-table-filter [data]="filterData" (filterData)="onChipsRefresh($event)"></app-table-filter>
                    </div>
                  </ng-template>
                </p-overlayPanel>
              </span>
            </span>
          </div>
          <button
            pButton
            label="EXPORT"
            class="p-button-outlined button-div-flex"
            iconPos="left"
            icon="fa fa-upload"
            (click)="exportData()"
          ></button>
          <div class="button-div-flex" [pTooltip]="createQATooltip()">
            <button
              pButton
              label="ADD QUESTION"
              class="p-button-primary button-div-flex-second"
              iconPos="left"
              icon="pi pi-plus-circle"
              (click)="addQuestion()"
              [disabled]="accountIsNotLeaf() || !checkAddPerms"
            ></button>
          </div>
        </div>
      </div>
      <div class="chips-bar">
        <p-chip *ngFor="let chipText of chipsArray" [label]="chipText"></p-chip>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th class="smaller-columns">ID</th>
        <th class="p-selectable-row">SELECT</th>
        <th class="wider-columns" pSortableColumn="questionText">
          QUESTION
          <p-sortIcon field="questionText"></p-sortIcon>
        </th>
        <th class="wider-columns" pSortableColumn="answerText">
          ANSWER
          <p-sortIcon field="answerText"></p-sortIcon>
        </th>
        <th class="smaller-columns">LOCATIONS</th>
        <th class="smaller-columns" pSortableColumn="_lastUpdatedAt">
          ACTIVITY
          <p-sortIcon field="_lastUpdatedAt"></p-sortIcon>
        </th>
        <th class="smaller-columns" pSortableColumn="ans_count_cust">
          CUSTOMER ANSWERS
          <p-sortIcon field="ans_count_cust"></p-sortIcon>
        </th>
        <th class="smaller-columns">ACTIONS</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td class="smaller-columns">
          {{ rowData._id }}
        </td>
        <td *ngIf="false" class="p-selectable-row">
          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </td>
        <td class="wider-columns">
          <div class="table-question">{{ rowData.questionText }}</div>
        </td>
        <td class="wider-columns">
          {{ rowData.answerText }}
        </td>
        <td class="table-locations smaller-columns">
          Posted on
          <span (click)="openLocations(rowData._id)" class="table-locations-links"
          >{{ rowData.ques_count_live }}/{{ rowData.ques_count }} locations</span
          >
        </td>
        <td class="table-activity smaller-columns">
          <div class="activity-button" [ngClass]="rowData?._status | activityStatus">
            {{ rowData?._status | getStatus }}
          </div>
          {{
          rowData.scheduleTime
            ? rowData.scheduleTime?.strftime('%m/%d/%Y %H:%M')
            : rowData.createTime ? rowData.createTime?.strftime('%m/%d/%Y') :
              rowData._lastUpdatedAt.strftime('%m/%d/%Y')
          }}
        </td>
        <td class="smaller-columns">
          <div
            (click)="rowData.ans_count_cust > 0 && openAnswers(rowData)"
            class="answer-button"
            [ngClass]="rowData.ans_count_cust > 0 ? 'answer-button-yes answer-button-pointer' : 'answer-button-no'"
          >
            {{ rowData.ans_count_cust > 0 ? 'YES' : 'NO' }}
          </div>
        </td>
        <td class="table-actions smaller-columns">
          <ul class="table-actions-list">
            <li (click)="openEdit(rowData)" [class.disabled]="(rowData?._status !== 'AP') || !checkEditPerms">
              <i class="pi pi-pencil"></i> Edit</li>
            <li class="disabled" [pTooltip]="tooltipText"><i class="pi pi-map"></i> Add Location</li>
            <li [class]="deleteBtnClass(rowData)" (click)="openDelete(rowData)" [class.disabled]="!checkDeletePerms"><i class="pi pi-trash"></i> Delete</li>
          </ul>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
