import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IPublish, IReviewData } from '../../review-management.interfaces';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { ReviewResponseTemplateService } from '../../services/review-response-template.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-edit-response',
  templateUrl: './add-edit-response.component.html',
  styleUrls: ['./add-edit-response.component.scss'],
})
export class AddEditResponseComponent implements OnInit {
  private ngUnsubscribe$ = new Subject();
  private templateVariablesSet: Set<string> = new Set(['businessName', 'city', 'state', 'reviewerName', 'starRating', 'phoneNumber']);
  private templateVariablesMap: Map<string, string> = new Map();
  public reviewResponsesList: string[] = [];
  public loadResponseTemplate: boolean = false;

  public selReview: IReviewData;
  constructor(
    private dynamicDialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private dialogService: DialogService,
    private reviewResponseTemplateService: ReviewResponseTemplateService
  ) {}

  ngOnInit(): void {
    const { review, accountId, loginId } = this.dynamicDialogConfig.data;

    switch (review['vendorIdent']) {
      case 'gmb':
        this.selReview = { ...review, icon: `middle.jpeg` };
        break;
      case 'yelp-yelp':
        this.selReview = { ...review, icon: `icon-yelp.png` };
        break;
      default:
        this.selReview = { ...review, icon: `` };
        break;
    }

    this.getResponseTemplateList(review, accountId, loginId);
  }

  private getResponseTemplateList(selectedReview: IReviewData, accountId: number, loginId: number): void {
    this.loadResponseTemplate = true;
    const { starRating = null, reviewerComment = '' } = selectedReview;

    this.reviewResponseTemplateService
      .getResponseTemplateList(accountId, loginId, starRating, !!reviewerComment)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (res) => {
          let arr = [];

          res['items'].forEach((item, i) => {
            item?.responses?.forEach((el) => {
              if (el['response_text'].length) {
                arr.push(el['response_text']);
              }
            });
          });

          arr.forEach((reviewResp) => {
            this.templateVariablesSet.forEach((te) => {
              if (te === 'reviewerName') {
                const reviewerName = this.selReview['reviewerName'].split(' ')[0];
                this.templateVariablesMap.set(te.toLowerCase(), reviewerName || 'N/A');
              } else {
                this.templateVariablesMap.set(te.toLowerCase(), this.selReview[te] || 'N/A');
              }
            });

            reviewResp = reviewResp.replace(/\{\w+\}/gi, (n) => this.templateVariablesMap.get(n.replace(/[{}]/g, '')));
            this.reviewResponsesList.push(reviewResp);
          });

          this.loadResponseTemplate = false;
        },
        (err) => {
          this.loadResponseTemplate = false;
          this.reviewResponsesList = [];
        },
        () => {
          this.loadResponseTemplate = false;
        }
      );
  }

  public selectReviewText(reviewText: string): void {
    this.selReview.replyComment = reviewText;
  }

  public cancelSingleReply(): void {
    this.ref.close();
  }

  public confirmReply(): void {
    const publishObject: IPublish<IReviewData> = {
      type: 'single',
      data: this.selReview,
    };
    this.ref.close();
    this.dialogService.open(ConfirmationPopupComponent, {
      data: { ...publishObject },
      closable: false,
      width: '800px',
      height: '300px',
    });
  }
}
