import { Injectable } from '@angular/core';
import { GetSecretValueCommand, SecretsManagerClient } from '@aws-sdk/client-secrets-manager';

@Injectable({
  providedIn: 'root',
})

export class AwsSecretService {
  constructor() {}

  async getSecret(secretName: string) {
    let client = new SecretsManagerClient({ region: 'us-west-2' });
    const response = await client.send(
      new GetSecretValueCommand({
        SecretId: secretName,
      })
    ).then(r => {
      console.log(r)
    })
  }

}
