export enum SQL_LISTINGS_RAW_ROW_KEY {
  'DBID',
  'StoreCode',
  'BusinessName',
  'City',
  'State',
  //
  'YELPURL',
  'MAPQUESTURL',
  'YPDEXSPURLs',
  'APPLEMAPSURL',
  'BingURL',
  'GMBURL',
  'NEXTDOORURL',
  'TOMTOMURL',
  'FACTUALURL',
  'HEREURL',
  'PANDIOURL',
  'AMAZONALEXAURL',
  'FACEBOOKURL',
  'INFOGROUPURL',
  'LOCALEZEURL',
  'UBERURL',
  'YPCAURL',
  'FOURSQUARE'
}

export interface SqlListingsRow {
  locationId: number;
  location: string;
  storeCode: string;
  vendor: {
    gmb: string;
    yelp: string;
    mapquest: string;
    pandio: string;
    appleMaps: string;
    bing: string;
    nextdoor: string;
    factual: string;
    tomtom: string;
    here: string;
    amazonalexa: string;
    facebook: string;
    infogroup: string;
    localeze: string;
    uber: string;
    ypca: string;
    foursquare?: string;
  };
}

export type SqlListingsRowDictType<T> = {
  [key in keyof T]: string;
};

export interface SqlListingsQueryObj {
  queryIdent: string;
  querySql?: any;
}

export interface SqlListingsRawData {
  account: string; // "aaasocal"
  accountName: string; // "AAA Automobile Club of Southern California"
  colDefs: SqlListingsColDef[];
  descr: string; // "Returns all publsihers URL for a given account."
  ident: string; // "all_publishers_url"
  name: string; // "All Publishers URL"
  rowCount: number; // 327
  rows: SqlListingsRowDictType<SQL_LISTINGS_RAW_ROW_KEY>[];
  sql: string;
  status: string; // 'ok';
}

interface SqlListingsColDef {
  align: string; // 'r';
  index: number; // 0;
  isArray: string; // '';
  name: string; // 'DB ID';
  name_lc: string; // 'db id';
  pg_type: string; // 'int8';
  scale: null;
  size: number; // 8;
  type: string; // 'int';
  width: number; // 50;
}

// appleMaps row:
// 0: "1"
// 1: 99
// 2: "A"
// 3: "Y"
// 4: "AAA Alhambra Insurance and Member Services"
// 5: "-NULL-"
// 6: "https://maps.apple.com/place?auid=10225967478530095542"

// pandio rows:
// 0: 2
// 1: [https]

type SqlListingsArrayRow = string[];
// {
//   0: 197;
//   1: '0E8FB40E-FA64-4BA5-B189-0178CCAADD8B';
//   2: 'AAA Raytown Insurance and Member Services';
//   3: 'Raytown';
//   4: 'MO';
//   5: 'https://www.yelp.com/biz/aaa-raytown-raytown?adjust_creative=P_TwBYTdY_PDw12TMXrf3w&utm_campaign=yelp_api_v3&utm_medium=api_v3_business_lookup&utm_source=P_TwBYTdY_PDw12TMXrf3w';
//   6: '-NULL-';
//   7: '-NULL-';
//   8: 'https://maps.apple.com/place?auid=9377510752891717994';
//   9: '-NULL-';
//   10: '-NULL-';
// }
