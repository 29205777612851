<app-directory-wrapper *ngIf="newDirectories" [title]="'Managed Directories'" [count]="newDirectories?.length">
  <ng-container directories>
    <ng-container *ngFor="let d of newDirectories">
      <app-directory-card
        [directory]="d"
        [account]="currentAccount"
        [location]="currentLocation"
        [disableEdit]="disableEdit"
      ></app-directory-card>
    </ng-container>
    <app-directory-card
      *ngIf="newDirectories && !sessionService.hasPerm('admin.account', 'r')"
      [isStub]="true"
    ></app-directory-card>
  </ng-container>

  <ng-container listings>
    <app-directory-location-listings
      *ngIf="currentLocation"
      [account]="currentAccount"
      [location]="currentLocation"
    ></app-directory-location-listings>

    <app-directory-account-listings *ngIf="!currentLocation"></app-directory-account-listings>
  </ng-container>
</app-directory-wrapper>
