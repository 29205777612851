import { Injectable } from '@angular/core';
import { SocketService } from '../../backend-adapter/socket.service';
import { DirectoryWrapperComponent } from './directory-wrapper/directory-wrapper.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from "rxjs";
import { generateJWTToken } from "src/app/core/helpers/jwt-token.utils";

@Injectable({
  providedIn: DirectoryWrapperComponent,
})
export class DirectoryService {
  constructor(private socketService: SocketService, private http: HttpClient) {}

  searchCats(event, vendor: string, callback: (res) => void): void {
    const q = event.query;
    const where = ['-and', `vendorIdent = '${vendor}'`];
    const words = q.trim().split(/\s+/);

    for (const word of words) {
      if (word.length >= 3) {
        where.push(`name ~* '\\y${word}' or code ~* '\\y${word}'`);
      }
    }

    this.socketService
      .sendRequest('get-vendor-cat-coll', {
        where,
        limit: 50,
        order: `case when (name ~* '\\y${words[0]}\\y') then 0 else 1 end`,
      })
      .then((res: any) => callback(res))
      .catch((err) => console.log('category search error: ', err));
  }

  /**
   * @description To get categories list of a location.
   * @param location_id
   * @param accountId
   * @param userId
   * @returns
   */
  public getLocationCategories(location_id: number, accountId: number, userId: any): Observable<any> {
    const payloadObj = {
      iss: 'https://platform.renderseo.com',
      sub: userId, // user id
      account_id: accountId,
      iat: new Date().getTime(),
      exp: new Date().getTime() + 3 * 6000,
    };
    const jwtToken = generateJWTToken(payloadObj, '2c4c4d06f0b35b921039d9df2725205d981ac00bf196eb71b02ce7571432e30a');
    const headers = new HttpHeaders().set('Authorization', `${jwtToken}`);
    const url: string = `https://io.renderseo.com/location-dev/categories/all?account_id=${accountId}&location_id=${location_id}`;
    return this.http.get(url, { headers: headers });
  }
}
