<div class="listings-table-container">
  <!-- <pre>{{ directory | json }}</pre> -->
  <h1 class="m-t-0">Listings</h1>
  <p-table
    #sqlTbl
    [value]="listings"
    dataKey="_id"
    scrollable="true"
    [resizableColumns]="true"
    autoLayout="true"
    responsive="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th class="vendor" pResizableColumn>Vendor</th>
        <th class="storeCode" pResizableColumn>Storecode</th>
        <th class="location" pResizableColumn>Location</th>
        <th class="url" pResizableColumn>Listings url</th>
      </tr>
      <app-rs-table-behaviors [searching]="loading" [items]="listings"></app-rs-table-behaviors>
    </ng-template>

    <ng-template pTemplate="body" let-r>
      <tr>
        <ng-container *ngIf="r['displayDir']">
          <td class="vendor">
            <img
              class="vendor-image"
              [src]="VENDOR_IMAGES[r.vendor]"
              appImageOnError
              [alt]="r.vendor"
              [title]="r.vendor"
            />
          </td>
          <td class="storeCode">{{ r.storeCode }}</td>
          <td class="location">{{ r.location }}</td>
          <td class="link">
            <ng-container *ngIf="r.link; else noLink">
              <div class="link-row" *ngIf="r.link | checkTypeof: 'string'">
                <a target="_blank" [href]="r.link | safeHtml: 'url'">
                  {{ r.link }}&nbsp;<i class="pi pi-external-link"></i>
                </a>
                <button
                  pButton
                  class="p-button-text"
                  icon="pi pi-copy rs-pi-icon-sized"
                  (mouseup)="handleCopyToClipboard(r.link)"
                  [cdkCopyToClipboard]="copyToClipboard"
                ></button>
              </div>
              <ng-container *ngIf="r.link | checkTypeof: 'array'">
                <div class="link-row" *ngFor="let link of r.link">
                  <a target="_blank" [href]="link | safeHtml: 'url'">
                    {{ link }}&nbsp;<i class="pi pi-external-link"></i>
                  </a>
                  <button
                    pButton
                    class="p-button-text"
                    icon="pi pi-copy rs-pi-icon-sized"
                    (mouseup)="handleCopyToClipboard(link)"
                    [cdkCopyToClipboard]="copyToClipboard"
                  ></button>
                </div>
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</div>

<ng-template #noLink> Not available </ng-template>
