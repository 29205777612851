<div class="templates">
  <div class="templates_desc">
    <p class="templates_desc__header">Review Response Templates.</p>
    <p-button pButtom (click)="addTemplate()">Add a template</p-button>
  </div>
  <div class="no_data" *ngIf="isLoadingData">
    <p class="no_data__text">Loading data...</p>
    <p-progressSpinner styleClass="w-2rem h-2rem" strokeWidth="2" fill="var(--surface-ground)"
      animationDuration="spinnerAnimationDuration">
    </p-progressSpinner>
  </div>
  <div class="templates_list" *ngIf="!isLoadingData">
    <app-review-template-card
      [reviewTemplates]="templatesList"
      (deleteTemplateEvent)="deleteTemplate($event)"
      [isDeletingTemplate]="isDeletingTemplate"
      ></app-review-template-card>
  </div>
</div>
