import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LocChangeLogService } from './loc-change-log.service';
import { SessionService } from '../core/backend-adapter/session.service';
import { formatDate } from '@angular/common';
import { NotifyService } from '../core/layouts/notifications/notify/notify.service';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-loc-change-log',
  styleUrls: ['./loc-change-log.component.scss'],
  templateUrl: './loc-change-log.component.html',
})
export class LocChangeLogComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private locChangeLogService: LocChangeLogService,
    private sessionService: SessionService,
    private notifyService: NotifyService
  ) {}

  @ViewChild('searchIn') searchIn: ElementRef;

  private userLoginId: number;
  private accountId: number;
  private query: string = '';
  private ngUnsubscribe$: Subject<any> = new Subject();
  private currentPageNumber: number;
  private paramName: string = `interval`;
  private searchText: string;
  private sortField: string = '';
  private sortOrder: string = '';

  public changeLocationsDataList: any[] = [];
  public selectedRange: number = 30;
  public dateRanges = [
    { name: 'Last 30 days', code: 30 },
    { name: 'Last 90 days', code: 90 },
  ];
  public customDate: any;
  public displayDropdown: boolean = false;
  public tableColumnHeaders: any[] = [];
  public isLoadingData: boolean;
  public isCustomDateSelected: boolean = false;
  public placegolderText: string = 'Select date range';
  public first: number = 0;
  public rows: number = 20;
  public totalRecords: number = 0;
  public searchByOptions: any[] = [
    { label: 'Storecode', value: 'storecode' },
    { label: 'City', value: 'city' },
    { label: 'State', value: 'state' },
    { label: 'Updated by', value: 'updated_by' },
    { label: 'Field name', value: 'field_name' },
    { label: 'Old value', value: 'old_value' },
    { label: 'New value', value: 'new_value' },
    // { label: 'Unselect', value: '' },
  ];
  public selectedSearchBy: string;
  public displaySearch: boolean = false;

  ngOnInit(): void {
    this.tableColumnHeaders = this.locChangeLogService.tableColums;

    this.sessionService.getCurrentUser$().subscribe((user) => {
      this.userLoginId = user?.login?._id;
    });

    this.sessionService.getSelectedAccount$().subscribe((account) => {
      this.accountId = account?._id || null;
    });

    // Init query with 3 months data.
    this.query = `${this.selectedRange}_days`;
    this.placegolderText = this.selectedRange ? 'Select date range' : `Select custom date range`;
  }

  ngAfterViewInit(): void {
    fromEvent<any>(this.searchIn.nativeElement, 'keyup')
      .pipe(
        map((event) => event?.target?.value),
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => (this.isLoadingData = true)),
        switchMap((value) => {
          this.searchText = `${this.selectedSearchBy}=${value}`;
          return this.locChangeLogService.getLocChangeLogData(
            this.userLoginId,
            this.accountId,
            this.query,
            this.paramName,
            this.currentPageNumber,
            this.searchText,
            this.sortField,
            this.sortOrder
          );
        })
      )
      .subscribe(
        (response) => {
          this.changeLocationsDataList = response['changes'].map((el) => {
            if (Array.isArray(el?.old_value) || Array.isArray(el.new_value)) {
              return {
                ...el,
                updated_at: formatDate(new Date(el.updated_at), 'yyyy-MM-dd HH:mm', 'en-US'),
                field_name: el.field_name.replace('.', ':'),
              };
            } else {
              return {
                ...el,
                old_value: [el.old_value],
                new_value: [el.new_value],
                updated_at: formatDate(new Date(el.updated_at), 'yyyy-MM-dd HH:mm', 'en-US'),
                field_name: el.field_name.replace('.', ':'),
              };
            }
          });
          this.totalRecords = response['total'];
          if (!this.changeLocationsDataList.length) {
            this.notifyService.info('No data available for selected range.');
          }
          this.isLoadingData = false;
        },
        (error) => {
          this.isLoadingData = false;
        },
        () => {
          this.isLoadingData = false;
        }
      );
  }

  public pageChange(event): void {
    this.first = event.first;
    this.rows = event.rows;
  }

  public onCalendarChange(event): void {
    this.isCustomDateSelected = true;
    this.selectedRange = null;
    this.placegolderText = !this.selectedRange ? 'Select date range' : `Select custom date range`;
    const year = formatDate(event, 'yyyy', 'en-US').replace(/-/g, '');
    const month = formatDate(event, 'MM', 'en-US').replace(/-/g, '');
    this.query = `${year}-${month}`;
    this.paramName = 'date';
    this.currentPageNumber = 1; // Reset the page number to 1.
    if (this.searchText) {
      this.getChangeLodData(this.paramName, this.currentPageNumber, this.searchText, this.sortField, this.sortOrder);
    } else {
      this.getChangeLodData(this.paramName, this.currentPageNumber, '', this.sortField, this.sortOrder);
    }
  }

  public onDropdownChange(event): void {
    this.isCustomDateSelected = false;
    this.customDate = null;
    this.placegolderText = !this.customDate ? 'Select date range' : `Select custom date range`;
    this.query = `${event?.value || this.selectedRange}_days`;
    this.paramName = 'interval';
    this.currentPageNumber = 1; // Reset the page number to 1.
    if (this.searchText) {
      this.getChangeLodData(this.paramName, this.currentPageNumber, this.searchText, this.sortField, this.sortOrder);
    } else {
      this.getChangeLodData(this.paramName, this.currentPageNumber, '', this.sortField, this.sortOrder);
    }
  }

  private getChangeLodData(
    paramName: string,
    pageNumber: number,
    searchFilter?: string,
    sortField?: string,
    sortOrder?: string
  ): void {
    this.isLoadingData = true;
    if (!this.accountId) {
      this.notifyService.error('Please select an account to view the data', 10000);
      this.isLoadingData = false;
    } else {
      this.locChangeLogService
        .getLocChangeLogData(
          this.userLoginId,
          this.accountId,
          this.query,
          paramName,
          pageNumber,
          searchFilter,
          sortField,
          sortOrder
        )
        .subscribe(
          (response) => {
            this.changeLocationsDataList = response['changes'].map((el) => {
              if (Array.isArray(el?.old_value) || Array.isArray(el.new_value)) {
                return {
                  ...el,
                  updated_at: formatDate(new Date(el.updated_at), 'yyyy-MM-dd HH:mm', 'en-US'),
                  field_name: el.field_name.replace('.', ':'),
                };
              } else {
                return {
                  ...el,
                  old_value: [el.old_value],
                  new_value: [el.new_value],
                  updated_at: formatDate(new Date(el.updated_at), 'yyyy-MM-dd HH:mm', 'en-US'),
                  field_name: el.field_name.replace('.', ':'),
                };
              }
            });
            this.totalRecords = response['total'];
            if (!this.changeLocationsDataList.length) {
              this.notifyService.info('No data available for selected range.');
            }
            this.isLoadingData = false;
          },
          (error) => {
            this.isLoadingData = false;
          },
          () => {
            this.isLoadingData = false;
          }
        );
    }
  }

  public lazyLoadLocations(event): void {
    let sortField: string;
    let sortOrder: string = '';

    if (!event.multiSortMeta) {
      this.sortField = '';
      this.sortOrder = '';
    } else {
      this.sortField = event.multiSortMeta[0]?.field || '';
      if (event.multiSortMeta[0].order === 1) {
        this.sortOrder = 'desc';
      } else if (event.multiSortMeta[0].order === -1) {
        this.sortOrder = 'asc';
      } else {
        this.sortOrder = '';
      }
    }

    this.currentPageNumber = event.first / 100 + 1;
    if (this.selectedRange) {
      this.query = `${this.selectedRange}_days`;
      if (this.searchText) {
        this.getChangeLodData('interval', this.currentPageNumber, this.searchText, this.sortField, this.sortOrder);
      } else {
        this.getChangeLodData('interval', this.currentPageNumber, '', this.sortField, this.sortOrder);
      }
    } else {
      const year = formatDate(this.customDate, 'yyyy', 'en-US').replace(/-/g, '');
      const month = formatDate(this.customDate, 'MM', 'en-US').replace(/-/g, '');
      this.query = `${year}-${month}`;
      if (this.searchText) {
        this.getChangeLodData('date', this.currentPageNumber, this.searchText, this.sortField, this.sortOrder);
      } else {
        this.getChangeLodData('date', this.currentPageNumber, '', this.sortField, this.sortOrder);
      }
    }
  }

  public searchFilterBy(event: boolean) {
    this.displaySearch = event;
  }

  public onSearchByChange(): void {
    if (!this.selectedSearchBy) {
      this.searchText = '';
      this.searchIn.nativeElement.value = '';
      this.getChangeLodData(this.paramName, this.currentPageNumber);
      // set surrent page to 1 if needed.
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
