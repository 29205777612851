import { NgModule } from '@angular/core';
import { ReviewResponseComponent } from './response-templates-list-view.component';
import { RSCommonModule } from 'src/app/core/feature-modules/facade.module';
import { ReviewTemplateCard } from '../components/review-template-card/review-template-card.component';


const components = [ReviewResponseComponent, ReviewTemplateCard];



@NgModule({
  declarations: [...components],
  exports: [ReviewTemplateCard],
  imports: [RSCommonModule],
  providers: [],
})



export class ReviewResponseModule {}
