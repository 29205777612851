import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/core/backend-adapter/session.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IAccount } from 'src/app/acct-comps/accounts.interfaces';
import { ReviewResponseTemplateService } from '../services/review-response-template.service';
import { AccountsService } from 'src/app/acct-comps/accounts.service';
import { NotifyService } from 'src/app/core/layouts/notifications/notify/notify.service';

@Component({
  selector: 'app-review-response',
  templateUrl: './response-templates-list-view.component.html',
  styleUrls: ['./response-templates-list-view.component.scss'],
})
export class ReviewResponseComponent implements OnInit, OnDestroy {
  private userLoginId: number;
  private currentAccount: number;
  private ngUnsubscribe$: Subject<any> = new Subject();
  private allAccountsList: any[] = [];

  public templatesList: any[] = [];
  public isLoadingData: boolean = true;
  public spinnerAnimationDuration: string = '0.8s';
  public isDeletingTemplate: boolean = false;

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private reviewResponseTemplateService: ReviewResponseTemplateService,
    private accountsService: AccountsService,
    private notifyService: NotifyService
  ) {}

  ngOnInit(): void {
    this.getCurrentAccountDetails();
    this.getCurrentUserDetails();
    this.getAllAccountsList();
    this.getTemplatesList();
  }

  /**
   * @description: Navigate to page to create a template response.
   * @returns: void
   * @arguments: void
   */
  public addTemplate(): void {
    this.router.navigate(['/add-template-response']);
  }

  /**
   * @description Get list of all Accounts.
   */
  private getAllAccountsList(): void {
    this.accountsService
      .getAccounts()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((accounts) => {
        const mappedAccounts = this.accountsService.mapAccountsWithSubaccounts(accounts['collection']);
        this.allAccountsList = mappedAccounts;
      });
  }

  /**
   * @description Get the list of Review templates.
   */
  private getTemplatesList(): void {
    this.isLoadingData = true;

    if (!this.currentAccount) {
      this.notifyService.info('Please select an account to view the Review templates.');
      this.isLoadingData = false;
    } else {
      this.reviewResponseTemplateService
        .getTemplateList(this.currentAccount, this.userLoginId)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(
          (response) => {
            this.templatesList = response['items'];
            this.isLoadingData = false;

            this.mapAccountIdentToTemplate();
            if (this.templatesList.length) {
              this.notifyService.success('Review templates loaded successfully.');
            } else {
              this.notifyService.info('Review templates not found.');
            }
          },
          (err) => {
            this.isLoadingData = false;
            this.notifyService.error('Error while fetching templates.');
          },
          () => {
            this.isLoadingData = false;
          }
        );
    }
  }

  /**
   * @description: Get the account details.
   */
  private getCurrentAccountDetails(): void {
    this.sessionService
      .getSelectedAccount$()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((account: IAccount) => {
        this.currentAccount = account?._id;
      });
  }

  /**
   * @description: Get the current user details.
   * @returns: void
   * @arguments: void
   */
  private getCurrentUserDetails(): void {
    this.sessionService
      .getCurrentUser$()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((user) => {
        this.userLoginId = user?.login?._id;
      });
  }

  private mapAccountIdentToTemplate(): void {
    this.templatesList.forEach((template, i) => {
      // To check for emapty values and replace with N/A...
      template['filter']['review_text_presence'] = template['filter']['review_text_presence'] === 'null' ? 'Any' : template['filter']['review_text_presence'];
      template['filter']['thumb_rating'] = !template['filter']['thumb_rating']
        ? 'N/A'
        : template['filter']['thumb_rating'];

      // Add ident property to the template
      this.templatesList[i]['accounts'].forEach((accId, y) => {
        this.allAccountsList.forEach((acc, x) => {
          if (accId === acc._id) {
            this.templatesList[i]['ident'] = [...(this.templatesList[i]['ident'] || []), acc.ident];
          }
        });
      });
    });
  }

  /**
   * @description Delete the template.
   * @param template template object to be deleted
   */
  public deleteTemplate(template: any): void {
    this.isDeletingTemplate = true;
    this.reviewResponseTemplateService.deleteTemplate(template.id, this.currentAccount, this.userLoginId).subscribe(
      (res) => {
        this.isDeletingTemplate = false;
        this.templatesList = this.templatesList.filter((temp) => temp.id !== template.id);
        this.notifyService.success('Template deleted successfully.');
      },
      (err) => {
        this.isDeletingTemplate = false;
        this.notifyService.error('Error while deleting the template.');
      }
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
