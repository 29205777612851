<div class="loc-change">
  <div class="date-range">
    <div _ngcontent-iem-c189="" class="header-child-title">Location Change Log.</div>
    <div>
      <p-dropdown [options]="dateRanges" [(ngModel)]="selectedRange" optionLabel="name" optionValue="code"
        (onChange)="onDropdownChange($event)" [placeholder]="placegolderText">
      </p-dropdown>
      <p-calendar class="month-picker" [(ngModel)]="customDate" view="month" dateFormat="mm/yy" [readonlyInput]="true"
        [showIcon]="true" (onSelect)="onCalendarChange($event)" [placeholder]="placegolderText">
      </p-calendar>
    </div>
  </div>

  <p-table #dt [value]="changeLocationsDataList" [loading]="isLoadingData" [paginator]="true" [rows]="100"
    [first]="first" [showCurrentPageReport]="true" [tableStyle]="{ 'min-width': '50rem' }"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" (onPage)="pageChange($event)"
    sortMode="multiple" sortOrder="-1" [globalFilterFields]="['storecode', 'updated_by', 'field_name']"
    (onLazyLoad)="lazyLoadLocations($event)" [lazy]="true" [totalRecords]="totalRecords" [lazyLoadOnInit]="true">
    <ng-template pTemplate="caption">
      <div class="search">
        <p-dropdown [options]="searchByOptions" [(ngModel)]="selectedSearchBy" optionLabel="label" optionValue="value"
          (onChange)="onSearchByChange()" placeholder="Search field" [showClear]="true">
        </p-dropdown>

        <input #searchIn [disabled]="!selectedSearchBy" class="search_input" pInputText type="text"
          [class.disable_input__search]="!selectedSearchBy" placeholder="Search keyword" />
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="location_id">Store Code
          <p-sortIcon field="location_id"></p-sortIcon>
        </th>
        <th>Address</th>
        <th pSortableColumn="updated_at">Updated at
          <p-sortIcon field="updated_at"></p-sortIcon>
        </th>
        <th pSortableColumn="updated_by">Update by
          <p-sortIcon field="updated_by"></p-sortIcon>
        </th>
        <th class="field_name" pSortableColumn="field_name">Field name
          <p-sortIcon field="field_name"></p-sortIcon>
        </th>
        <th class="old_value">Old value</th>
        <th class="new_value">New value</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-data>
      <tr>
        <td>{{ data?.storecode || "--" }}</td>
        <td>{{ data?.address }} <br> {{ data?.city }} <br>{{ data?.state }} </td>
        <td>{{ data?.updated_at || "--" }}</td>
        <td>{{ data?.updated_by || "--" }}</td>
        <td>{{ data?.field_name || "--" }}</td>
        <td>
          <div *ngFor="let oldV of data?.old_value">
            {{ oldV }} <br>
          </div>
        </td>
        <td>
          <div *ngFor="let newV of data?.new_value">
            {{ newV }} <br>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<ng-template #displaySearchFilters>
  <div class="search_filter">
    <p-calendar [(ngModel)]="customDate" view="month" dateFormat="mm/yy" [readonlyInput]="true" [showIcon]="true"
      (onSelect)="onCalendarChange($event)" [placeholder]="placegolderText">
    </p-calendar>
  </div>
</ng-template>
